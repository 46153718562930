/*
Name: 			theme.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	@@version
*/
html {
  direction: ltr;
  overflow-x: hidden;
  box-shadow: none !important;
}

body {
  background-color: #FFF;
  color: #777;
  @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600&display=swap');
  font-family: Barlow, "Open Sans", Arial sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
body a {
  outline: none !important;
}

li {
  line-height: 24px;
}

/* Responsive */
@media (max-width: 479px) {
  body {
    font-size: 13px;
  }
}

.main-pages {
  padding-top: 50px;
}

/* Header */
#header {
  position: relative;
  z-index: 100;
}
#header .header-body {
  background: #FFF;
  border-top: 3px solid #EDEDED;
  border-bottom: 1px solid transparent;
  padding: 8px 0;
  -webkit-transition: min-height 0.3s ease;
  -moz-transition: min-height 0.3s ease;
  transition: min-height 0.3s ease;
  width: 100%;
  z-index: 1001;
  min-height: 125px;
}
#header.header-no-min-height .header-body {
  min-height: 0 !important;
}
#header .container {
  position: relative;
}
#header .header-container {
  position: relative;
  display: table;
}
#header .header-top {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ededed;
  margin-top: -13px;
  padding: 8px 0;
  position: relative;
  z-index: 4;
}
#header .header-top::after {
  clear: both;
  content: "";
  display: table;
}
#header .header-top p {
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  line-height: 37px;
  float: left;
}
#header .header-top p .fa, #header .header-top p .icons {
  position: relative;
  top: 1px;
}
#header .header-top .header-search {
  margin-top: 2px;
  margin-right: 0;
}
#header .header-top .header-nav-top {
  float: left;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
}
#header .header-top .header-social-icons {
  margin-top: 3px;
}
#header .header-top.header-top-colored {
  margin-top: -13px;
  border-top: 5px solid #CCC;
  border-bottom: 0;
  color: #FFF;
}
#header .header-top.header-top-colored p, #header .header-top.header-top-colored a, #header .header-top.header-top-colored span {
  color: #FFF;
}
#header .header-top.header-top-colored .form-control {
  border: 0;
}
#header .header-top.header-top-style-2 {
  background: #f6f6f6;
  margin-top: -16px;
}
#header .header-top.header-top-style-3 {
  margin-top: -16px;
  margin-bottom: 10px;
}
#header .header-top.header-top-style-4 {
  background: #E4E6ED;
  margin-top: -16px;
  margin-bottom: 10px;
  border-bottom: 0;
}
#header .header-row {
  display: table-row;
  clear: both;
}
#header .header-column {
  display: table-cell;
  vertical-align: middle;
}
#header .header-column .row {
  margin: 0;
}
#header .header-column .header-row {
  display: block;
  clear: right;
}
#header .header-column .header-extra-info {
  list-style: outside none none;
  margin: 24px 0 0;
  padding: 0;
  float: right;
}
#header .header-column .header-extra-info li {
  display: inline-block;
  margin-left: 25px;
}
#header .header-column .header-extra-info li .feature-box small {
  position: relative;
  top: -4px;
}
#header .header-column.header-column-valign-top {
  vertical-align: top;
}
#header .header-column.header-column-valign-bottom {
  vertical-align: bottom;
}
#header .header-column.header-column-center {
  text-align: center;
}
#header .header-column.header-column-center .header-logo {
  width: 100% !important;
  padding: 9px 0;
}
#header .header-logo {
  float: left;
  position: relative;
}
#header .header-logo img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  margin: 15px 15px 15px 0;
  top: 0;
}
#header .header-nav-top {
  float: right;
  margin-top: 10px;
  margin-left: 10px;
}
#header .header-nav-top .nav > li > a, #header .header-nav-top .nav > li > span {
  color: #999;
  font-size: 0.9em;
  padding: 6px 10px;
  display: inline-block;
}
#header .header-nav-top .nav > li > a .fa, #header .header-nav-top .nav > li > a .icons, #header .header-nav-top .nav > li > span .fa, #header .header-nav-top .nav > li > span .icons {
  margin-right: 3px;
  position: relative;
  top: 1px;
}
#header .header-nav-top .nav > li > a:hover, #header .header-nav-top .nav > li > a a:focus {
  background: #EEE;
}
#header .header-nav-top .nav > li.open > .dropdown-menu-toggle {
  border-radius: 4px 4px 0 0;
}
#header .header-nav-top .dropdown-menu {
  border-color: #EEE;
  margin-top: -2px;
  border-radius: 4px 0 4px 4px;
  padding: 0;
}
#header .header-nav-top .dropdown-menu a {
  color: #999;
  padding: 6px 12px;
  font-size: 0.9em;
}
#header .header-nav-top .flag {
  margin-right: 2px;
}
#header .header-search {
  float: right;
  margin-top: 10px;
  margin-left: 10px;
}
#header .header-search .form-control {
  border-radius: 20px;
  font-size: 0.9em;
  height: 34px;
  margin: 0;
  padding: 6px 12px;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  transition: width 0.3s ease;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 170px;
}
#header .header-search .btn-default {
  background: transparent;
  border: 0 none;
  color: #CCC;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
#header .header-search .btn-default:hover {
  color: #000;
}
#header .header-social-icons {
  float: right;
  margin: 15px 0 0 10px;
}
#header .header-btn-collapse-nav {
  background: #CCC;
  color: #FFF;
  display: none;
  float: right;
  margin: 12px 0 8px 15px;
}
#header.header-narrow .header-body {
  min-height: 0;
}
#header.header-narrow .header-logo img {
  margin: 12px 12px 12px 0;
}
#header.header-narrow .header-nav-main {
  margin-bottom: 0;
}
#header.header-no-border-bottom .header-body {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}

html:not(.boxed) #header.header-full-width .header-container {
  width: 100%;
}

html #header.header-transparent {
  min-height: 0 !important;
  width: 100%;
  position: absolute;
}
html #header.header-transparent .header-body {
  position: relative;
  top: 0;
  background: transparent;
  border-top: 0;
  border-bottom: 0;
  box-shadow: none;
}
html #header.header-transparent .header-body:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #FFF;
  opacity: 0;
  border-bottom: 1px solid #f1f1f1;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
html.sticky-header-enabled #header.header-transparent .header-body {
  position: fixed;
}
html.sticky-header-active #header.header-transparent .header-body {
  border-bottom: 0;
  box-shadow: none;
}
html.sticky-header-active #header.header-transparent .header-body:before {
  opacity: 1;
}
@media (max-width: 991px) {
  html.sticky-header-active #header.has-nav-bar .header-logo img {
    top: 86px;
    padding: 5px 8px;
    z-index: 1;
  }
}

html #header.header-semi-transparent, html #header.header-semi-transparent-light {
  min-height: 0 !important;
  width: 100%;
  position: absolute;
}
html #header.header-semi-transparent .header-body, html #header.header-semi-transparent-light .header-body {
  position: relative;
  top: 0;
  background: transparent;
  border-top: 0;
  border-bottom: 0;
  box-shadow: none;
}
html #header.header-semi-transparent .header-body:before, html #header.header-semi-transparent-light .header-body:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  border-bottom: 0;
  opacity: 0.3;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
html.sticky-header-enabled #header.header-semi-transparent .header-body {
  position: fixed;
}
html.sticky-header-active #header.header-semi-transparent .header-body {
  border-bottom: 0;
  box-shadow: none;
}
html.sticky-header-active #header.header-semi-transparent .header-body:before {
  opacity: 0.8;
}

html #header.header-semi-transparent-light .header-body:before {
  opacity: 0.1;
  background: #FFF;
}
html.sticky-header-enabled #header.header-semi-transparent-light .header-body {
  position: fixed;
}
html.sticky-header-active #header.header-semi-transparent-light .header-body {
  border-bottom: 0;
  box-shadow: none;
}
html.sticky-header-active #header.header-semi-transparent-light .header-body:before {
  border-bottom: 1px solid #f1f1f1;
  opacity: 0.9;
}

html #header.header-transparent-bottom-border .header-body {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

html.sticky-header-active #header .header-body {
  position: fixed;
  border-bottom-color: #e9e9e9;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

html:not(.sticky-header-active) #header.header-transparent-sticky-deactive .header-body:before {
  background: transparent !important;
}

/* Header Nav Main */
@media (min-width: 992px) {
  #header .header-nav-main {
    float: right;
    margin: 12px 0 0;
    min-height: 45px;
  }
  #header .header-nav-main nav > ul > li > a {
    display: inline-block;
    border-radius: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 13px;
    text-transform: uppercase;
  }
  #header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-caret-down {
    display: none;
  }
  #header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
    border-color: #CCC transparent transparent transparent;
    border-style: solid;
    border-width: 4px;
    content: " ";
    float: right;
    margin-top: 7px;
    margin-left: 4px;
  }
  #header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li:hover > a {
    background: #CCC;
    color: #FFF;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    top: -10000px;
    display: block;
    opacity: 0;
    left: auto;
    border-radius: 0 0px 0px;
    border: 0;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin: 0;
    min-width: 200px;
    padding: 0;
    text-align: left;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
    border-bottom: 1px solid #f4f4f4;
    color: #777;
    font-size: 0.9em;
    font-weight: 400;
    padding: 8px 20px 8px 8px;
    position: relative;
    text-transform: none;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu {
    position: relative;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a .fa-caret-down {
    display: none;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
    border-color: transparent transparent transparent #CCC;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    content: " ";
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    left: 100%;
    display: block;
    margin-top: -8px;
    margin-left: -1px;
    border-radius: 0px;
    opacity: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    opacity: 1;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:last-child a {
    border-bottom: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown.open > a, #header .header-nav-main nav > ul > li.dropdown:hover > a {
    padding-bottom: 15px;
  }
  #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: auto;
    display: block;
    opacity: 1;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega {
    position: static;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
    border-radius: 0px;
    left: 15px;
    right: 15px;
    width: auto;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-content {
    padding: 20px 30px;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title {
    color: #333333;
    display: block;
    font-size: 1em;
    font-weight: 600;
    margin-top: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title:first-child {
    margin-top: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    border: 0 none;
    border-radius: 0px;
    color: #777;
    display: block;
    font-size: 0.9em;
    font-weight: normal;
    margin: 0 0 0 -8px;
    padding: 3px 8px;
    text-shadow: none;
    text-transform: none;
    text-decoration: none;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
    background: #f4f4f4;
  }
  #header .header-nav-main.header-nav-main-no-arrows nav > ul > li a.dropdown-toggle {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  #header .header-nav-main.header-nav-main-no-arrows nav > ul > li a.dropdown-toggle:after {
    display: none;
  }
  #header .header-nav-main.header-nav-main-square {
    margin-bottom: -9px;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li > a {
    border-radius: 0;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
    margin-top: -1px;
    border-radius: 0;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    border-radius: 0;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega > .dropdown-menu {
    border-radius: 0;
  }
  #header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    border-radius: 0;
  }
  #header .header-nav-main a > .thumb-info-preview {
    position: absolute;
    display: block;
    left: 100%;
    opacity: 0;
    border: 0;
    padding-left: 10px;
    background: transparent;
    overflow: visible;
    margin-top: 15px;
    top: -10000px;
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
  }
  #header .header-nav-main a > .thumb-info-preview .thumb-info-wrapper {
    background: #FFF;
    display: block;
    border-radius: 0px;
    border: 0;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin: 0;
    padding: 4px;
    text-align: left;
    width: 190px;
  }
  #header .header-nav-main a > .thumb-info-preview .thumb-info-image {
    transition: all 6s linear 0s;
    width: 182px;
    height: 136px;
    min-height: 0;
  }
  #header .header-nav-main a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
    top: 0;
    opacity: 1;
    margin-top: -5px;
  }
  #header .header-nav-main .dropdown-reverse a > .thumb-info-preview {
    transform: translate3d(20px, 0, 0);
    right: 100%;
    left: auto;
    padding-left: 0;
    margin-right: 10px;
  }
  #header .header-nav-main .dropdown-reverse a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
  }
  #header .header-nav {
    display: block !important;
  }
  #header .header-nav.header-nav-stripe nav > ul > li > a, #header .header-nav.header-nav-stripe nav > ul > li:hover > a {
    background: transparent;
    padding: 35px 13px;
    margin: 0;
  }
  #header .header-nav.header-nav-stripe nav > ul > li > a {
    color: #444;
  }
  #header .header-nav.header-nav-stripe nav > ul > li > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }
  #header .header-nav.header-nav-stripe nav > ul > li:hover > a {
    color: #FFF;
  }
  #header .header-nav.header-nav-top-line nav > ul > li > a, #header .header-nav.header-nav-top-line nav > ul > li:hover > a {
    background: transparent;
    color: #444;
    padding: 35px 13px;
    margin: 0;
  }
  #header .header-nav.header-nav-top-line nav > ul > li > a:before, #header .header-nav.header-nav-top-line nav > ul > li:hover > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    top: -2px;
    left: 0;
    opacity: 0;
  }
  #header .header-nav.header-nav-top-line nav > ul > li.active > a:before, #header .header-nav.header-nav-top-line nav > ul > li:hover > a:before {
    opacity: 1;
  }
  #header .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li > a, #header .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
    background: transparent;
    color: #444;
    padding: 35px 13px;
    margin: 0;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown li a {
    border-bottom-color: #2a2a2a;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu {
    background: #1e1e1e;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a {
    color: #969696;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a:hover, #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a:focus {
    background: #282828;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-title {
    color: #ababab;
  }
  #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
    background: #282828;
  }
  #header .header-nav.header-nav-dark-dropdown .header-social-icons {
    margin-top: 27px;
  }
  #header .header-nav.header-nav-center .header-nav-main {
    float: none;
    margin: 0;
  }
  #header .header-nav.header-nav-center .header-nav-main > nav {
    width: 100%;
    text-align: center;
  }
  #header .header-nav.header-nav-center .header-nav-main > nav > ul > li {
    display: inline-block;
    float: none;
    text-align: left;
  }
  #header .header-nav-main {
    display: block !important;
    height: auto !important;
  }
  #header .header-nav-bar {
    background: #F4F4F4;
    padding: 0 10px 5px;
    margin-bottom: 0;
  }
  #header .header-nav-bar .header-nav-main {
    float: left;
    margin-bottom: 0;
  }
}
/* Header Narrow */
@media (min-width: 992px) {
  #header.header-narrow .header-nav.header-nav-stripe nav > ul > li > a, #header.header-narrow .header-nav.header-nav-stripe nav > ul > li:hover > a, #header.header-narrow .header-nav.header-nav-dark-dropdown nav > ul > li > a, #header.header-narrow .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
    padding: 20px 13px;
  }
  #header.header-narrow .header-nav.header-nav-top-line nav > ul > li > a, #header.header-narrow .header-nav.header-nav-top-line nav > ul > li:hover > a {
    padding: 22px 13px;
  }
  #header.header-narrow .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle:after, #header.header-narrow .header-nav.header-nav-top-line nav > ul > li:hover > a.dropdown-toggle:after {
    top: 22px;
  }
}
@media (min-width: 992px) {
  #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
    margin-top: 1px;
  }

  html.sticky-header-active #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu,
  html.ie9 #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
    margin-top: 0px;
  }

  html.sticky-header-active.ie9 #header.header-flex.header-transparent-bottom-border .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
    margin-top: -1px;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-light nav > ul > li > a {
    color: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li > a.dropdown-toggle:after {
    border-color: #FFF transparent transparent transparent;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > a, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
    background: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > a.dropdown-toggle:after, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a.dropdown-toggle:after {
    border-color: #CCC transparent transparent transparent;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu {
    border-top-color: #FFF;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
    border-top-color: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.active > a {
    background: #FFF;
  }
  #header .header-nav-main.header-nav-main-light nav > ul > li.active > a.dropdown-toggle:after {
    border-color: #CCC transparent transparent transparent;
  }
  #header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:hover, #header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:focus {
    background: #f5f5f5;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-mega-sub-nav li a {
    -webkit-transition: -webkit-transform 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }
  #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover .dropdown-mega-sub-nav li a {
    transform: translate3d(0, 0, 0);
  }
  #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }
  #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-2 nav > ul > li.dropdown .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
    opacity: 0;
  }
  #header .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, -1px, 0);
    opacity: 1;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-3 nav > ul > li.dropdown .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, 10px, 0);
  }
  #header .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }
  #header .header-nav-main-effect-4 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }
  #header .header-nav-main-effect-4 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  #header .header-nav-main-effect-4 nav > ul > li.dropdown.dropdown-reverse .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(20px, 0, 0);
    left: auto;
    right: 100%;
    opacity: 0;
  }
  #header .header-nav-main-effect-4 nav > ul > li.dropdown.dropdown-reverse:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media (min-width: 992px) {
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown.dropdown-reverse .dropdown-menu li.dropdown-submenu > .dropdown-menu {
    -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(20px, 0, 0);
    left: auto;
    right: 100%;
    opacity: 0;
  }
  #header .header-nav-main-sub-effect-1 nav > ul > li.dropdown.dropdown-reverse .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
/* Header Nav Main Mobile */
@media (max-width: 991px) {
  #header .header-nav-main {
    background: transparent;
    clear: both;
    float: none;
  }
  #header .header-nav-main nav {
    margin: 0 0 6px;
  }
  #header .header-nav-main nav > ul li {
    border-bottom: 1px solid #e8e8e8;
    clear: both;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  #header .header-nav-main nav > ul li a {
    font-size: 13px;
    font-style: normal;
    line-height: 20px;
    padding: 7px 8px;
    margin: 1px 0;
    border-radius: 4px;
  }
  #header .header-nav-main nav > ul li a .fa-caret-down {
    line-height: 35px;
    min-height: 38px;
    min-width: 30px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 0;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu {
    background: transparent;
    padding: 0;
    margin: 0;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    clear: both;
    display: none;
    float: none;
    position: static;
  }
  #header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu.opened > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }
  #header .header-nav-main nav > ul li.dropdown.opened > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }
  #header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-title {
    margin-top: 10px;
    display: block;
  }
  #header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav {
    margin: 0 0 0 20px;
    padding: 0;
    list-style: none;
  }
  #header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    display: block;
    text-decoration: none;
  }
  #header .header-nav-main nav > ul li:last-child {
    border-bottom: 0;
  }
  #header .header-nav-main nav > ul > li > a {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1px;
    margin-bottom: 1px;
  }
  #header .header-nav-main nav > ul > li.active > a, #header .header-nav-main nav > ul > li.active > a:focus, #header .header-nav-main nav > ul > li.active > a:hover {
    color: #FFF;
  }
  #header .header-nav-main nav .not-included {
    margin: 0;
  }
  #header .header-nav-main a > .thumb-info-preview {
    display: none !important;
  }
  #header .header-btn-collapse-nav {
    outline: 0;
    display: block;
    position: relative;
    z-index: 1;
  }
  #header .header-nav-bar {
    margin: 0 auto;
  }
  #header .header-nav-bar .header-btn-collapse-nav {
    margin-top: 14px;
  }
  #header.header-transparent .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #FFF;
    border-radius: 4px;
  }
  #header.header-semi-transparent .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #FFF;
    border-radius: 4px;
  }
  #header.header-semi-transparent-light .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #FFF;
    border-radius: 4px;
  }

  html.sticky-header-enabled #header .header-nav-main {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  html.sticky-header-enabled #header .header-nav-main.collapsing {
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  html:not(.sticky-header-active) #header.header-transparent .header-nav-main nav > ul > li:not(.active) > a {
    color: #FFF;
  }
  html:not(.sticky-header-active) #header.header-transparent .header-nav-main nav > ul > li:not(.active) > a.dropdown-toggle:after {
    border-color: #FFF transparent transparent transparent;
  }

  html #header.header-semi-transparent .header-nav-main nav > ul > li:not(.active) > a {
    color: #FFF;
  }
  html #header.header-semi-transparent .header-nav-main nav > ul > li:not(.active) > a.dropdown-toggle:after {
    border-color: #FFF transparent transparent transparent;
  }

  html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li:not(.active) > a {
    color: #FFF;
  }
  html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li:not(.active) > a.dropdown-toggle:after {
    border-color: #FFF transparent transparent transparent;
  }

  html:not(.ie) #header.header-flex .header-container {
    display: flex;
    height: 100%;
  }
  html:not(.ie) #header.header-flex .header-row {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
  }
  html:not(.ie) #header.header-flex .header-column {
    display: flex;
    flex-flow: column;
  }
  html:not(.ie) #header.header-flex .header-column:nth-child(1) {
    flex: 1;
  }
  html:not(.ie) #header.header-flex .header-column:nth-child(2) {
    flex: 8;
    align-items: flex-end;
  }
  html:not(.ie) #header.header-flex .header-logo {
    display: flex;
    flex-basis: 100%;
    align-items: center;
  }

  html.ie #header.header-flex .header-top {
    margin-bottom: -10px !important;
    margin-top: -4px !important;
  }
  html.ie #header.header-flex .header-body {
    padding: 0;
  }
  html.ie #header.header-flex .header-container {
    height: 100%;
  }
  html.ie #header.header-flex .header-row {
    display: table;
    width: 100%;
    height: 100%;
  }
  html.ie #header.header-flex .header-logo {
    margin: 8px 0 !important;
  }
  html.ie #header.header-flex .header-nav {
    height: 100%;
    margin: 0;
  }
  html.ie #header.header-flex .header-nav-main {
    margin: 0;
  }
  html.ie #header.header-flex .header-nav-main nav > ul > li > a {
    margin-bottom: -1px;
  }
  html.ie9 #header.header-flex .header-nav-main nav > ul > li > a, html.ie9 #header.header-flex .header-nav-main nav > ul > li:hover > a {
    position: relative;
  }
  html.ie9 #header.header-flex .header-nav-main nav > ul > li > a.dropdown-toggle, html.ie9 #header.header-flex .header-nav-main nav > ul > li:hover > a.dropdown-toggle {
    padding-right: 25px;
  }
  html.ie9 #header.header-flex .header-nav-main nav > ul > li > a.dropdown-toggle:after, html.ie9 #header.header-flex .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
    position: absolute;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  html #header.header-flex .header-top {
    margin-bottom: -2px;
  }
  html #header.header-flex .header-body {
    padding: 8px 0 0 0;
  }
  html #header.header-flex:not(.header-transparent-bottom-border) .header-body {
    border-bottom: 0 !important;
  }
  html #header.header-flex .header-logo {
    margin: 0 0 8px;
  }
  html #header.header-flex .header-nav-main,
  html #header.header-flex .header-nav-main nav,
  html #header.header-flex .header-nav-main nav > ul,
  html #header.header-flex .header-nav-main nav > ul > li {
    height: 100% !important;
  }
  html #header.header-flex .header-nav {
    margin-top: -9px;
  }
  html #header.header-flex .header-nav.header-nav-stripe nav > ul > li > a, html #header.header-flex .header-nav.header-nav-top-line nav > ul > li > a, html #header.header-flex .header-nav.header-nav-dark-dropdown nav > ul > li > a {
    display: flex;
    align-items: center;
    height: 100%;
  }
  html #header.header-flex .header-nav.header-nav-stripe nav > ul > li > a.dropdown-toggle, html #header.header-flex .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle, html #header.header-flex .header-nav.header-nav-dark-dropdown nav > ul > li > a.dropdown-toggle {
    height: calc(100% + 1px);
  }
  html #header.header-flex .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu,
  html #header.header-flex .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu,
  html #header.header-flex .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
    top: calc(100% + 1px);
  }
  html #header.header-flex .header-nav {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
  }
  html #header.header-flex .header-nav .header-social-icons {
    margin: 0 0 0 10px;
    order: 2;
  }
  html #header.header-flex .header-nav .header-social-icons li {
    margin: -1px 1px 0px 0px;
  }
  html #header.header-flex .header-nav-main {
    margin: 0 !important;
  }
  html #header.header-flex .header-nav-main nav > ul > li > a.dropdown-toggle:after {
    margin-top: 3px;
  }
  html #header.header-flex .header-nav-main.header-nav-main-square {
    margin-bottom: 0;
  }
  html #header.header-flex.header-semi-transparent .header-nav.header-nav-top-line nav > ul > li:hover, html #header.header-flex.header-semi-transparent .header-nav.header-nav-top-line nav > ul > li > a:before, html #header.header-flex.header-semi-transparent-light .header-nav.header-nav-top-line nav > ul > li:hover, html #header.header-flex.header-semi-transparent-light .header-nav.header-nav-top-line nav > ul > li > a:before {
    top: 0;
  }

  html.boxed #header.header-flex .header-nav.header-nav-stripe, html.boxed #header.header-flex .header-nav.header-nav-dark-dropdown {
    margin-top: -12px;
  }
  html.boxed.ie9 #header.header-flex .header-nav.header-nav-stripe, html.boxed.ie9 #header.header-flex .header-nav.header-nav-dark-dropdown {
    margin-top: 0 !important;
  }
}
/* Responsive */
@media (max-width: 991px) {
  #header .header-container {
    display: block;
  }
  #header .header-row {
    display: block;
  }
  #header .header-column {
    display: block;
  }
  #header .header-column .header-row {
    display: block;
  }
  #header.header-mobile-nav-only .header-logo {
    float: none;
  }
  #header.header-mobile-nav-only .header-nav {
    margin-top: -35px;
  }

  html.sticky-header-negative.sticky-header-active #header .header-logo {
    padding-top: 7px;
  }
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu {
  width: 40%;
  right: 15px;
  left: auto;
  border-radius: 6px 0 6px 6px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu a {
  transform: translate3d(0, 0, 0);
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .fa-user {
  margin-right: 5px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-mega-sub-title {
  margin-bottom: 10px;
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .sign-up-info, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .log-in-info {
  text-align: center;
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .signin-form {
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .signup-form, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .recover-form {
  display: none;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .signin-form {
  display: none;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .signup-form {
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .recover-form {
  display: none;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .signin-form, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .signup-form {
  display: none;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .recover-form {
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar .img-thumbnail {
  float: left;
  margin-right: 15px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar .img-thumbnail img {
  max-width: 55px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar strong {
  display: block;
  padding-top: 7px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar span {
  display: block;
  font-size: 12px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar p {
  margin: 0 0 0 25px;
  text-align: left;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options {
  list-style: none;
  margin: 6px 0 0;
  padding: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options a:hover {
  text-decoration: none;
}

/* Responsive */
@media (max-width: 991px) {
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin {
    margin-right: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-toggle {
    display: none;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu {
    display: block !important;
    margin: 0 !important;
    padding: 0 10px 0 10px;
    width: 100%;
    right: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu .dropdown-mega-sub-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 0;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .dropdown-toggle {
    display: none;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .dropdown-mega-content {
    margin: 15px 0 !important;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options {
    margin: 15px 0 0 0 !important;
  }
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options > li > a {
    line-height: 35px;
  }
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
  width: 40%;
  right: 15px;
  left: auto;
  border-radius: 6px 0 6px 6px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu a {
  transform: translate3d(0, 0, 0);
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table {
  width: 100%;
  margin-top: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a {
  background-color: transparent !important;
  color: #333;
  padding: 0;
  margin: 0;
  display: block;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a.remove {
  float: right;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a:hover {
  text-decoration: none;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .fa-shopping-cart {
  margin-right: 5px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-thumbnail {
  width: 120px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-actions {
  text-align: right;
  width: 80px;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid #DDD;
  clear: both;
}
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue .btn {
  margin-bottom: 10px;
}

/* Responsive */
@media (max-width: 991px) {
  #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
    margin: 15px 0;
    padding: 0 15px;
    width: 100%;
  }
}
html.side-header #header {
  min-height: 0 !important;
}

@media (min-width: 992px) {
  html.side-header .main .container, html.side-header #footer .container {
    padding: 0 35px;
    width: auto !important;
    max-width: 1210px;
  }
  html.side-header body > .body {
    margin: 0 0 0 255px;
    width: auto;
    overflow-x: hidden;
    overflow-y: visible;
  }
  html.side-header body > .body .forcefullwidth_wrapper_tp_banner .rev_slider_wrapper {
    width: 100% !important;
    left: auto !important;
  }
  html.side-header #header {
    background: #FFF;
    position: fixed;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.07);
    top: 0;
    left: 0;
    width: 255px;
    height: 100%;
  }
  html.side-header #header .header-body {
    border-top: 0;
  }
  html.side-header #header .header-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  html.side-header #header .header-search {
    margin: 5px 0 10px;
    float: none;
  }
  html.side-header #header .header-search .input-group {
    width: 100%;
  }
  html.side-header #header .header-search .input-group .form-control {
    width: 100%;
  }
  html.side-header #header .header-nav-top {
    margin: 0;
    float: none;
  }
  html.side-header #header .header-nav-top .nav > li > a, html.side-header #header .header-nav-top .nav > li > span {
    display: block;
  }
  html.side-header #header .header-logo {
    margin: 0;
    float: none;
    text-align: center;
    width: auto !important;
    height: auto !important;
    padding: 10px 0;
  }
  html.side-header #header .header-column {
    display: block;
  }
  html.side-header #header .header-column .header-row {
    clear: both;
  }
  html.side-header #header .header-nav-main {
    float: none;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
    content: "";
    border-color: transparent transparent transparent #CCC;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown.open > a, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > a {
    padding-bottom: 10px;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: 3px;
    left: 100%;
    border-top: 0;
    border-left: 5px solid #CCC;
    margin-left: -5px;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown li.dropdown-submenu:hover > .dropdown-menu {
    margin-top: -5px;
    border-top: 0;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown-mega {
    position: relative;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-menu {
    min-width: 720px;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu li a {
    padding-right: 8px;
    padding-left: 20px;
  }
  html.side-header #header .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu li.dropdown-submenu > a:after {
    border-width: 4px 4px 4px 0;
  }
  html.side-header #header .header-nav-main nav > ul > li > a {
    display: block;
  }
  html.side-header #header .nav-pills > li {
    float: none;
    margin: 0 0 1px 0;
  }
  html.side-header #header .header-social-icons {
    float: none;
    text-align: center;
    margin-top: 25px;
  }
  html.side-header-right body > .body {
    margin: 0 255px 0 0;
  }
  html.side-header-right #header {
    left: auto;
    right: 0;
  }
  html.side-header-right #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle {
    padding-right: 5px;
    padding-left: 5px;
  }
  html.side-header-right #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
    float: left;
    margin-right: 9px;
    margin-top: 6px;
  }
  html.side-header-right #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header-right #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    right: 100%;
    left: auto;
    border-right: 5px solid #CCC;
    border-left: 0;
    margin-left: 0;
    margin-right: -5px;
    border-radius: 4px 0 0 4px;
  }
  html.side-header-right #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
    left: 10px;
    right: initial;
  }
  html.side-header-semi-transparent body > .body {
    margin: 0;
  }
  html.side-header-semi-transparent #header {
    background: rgba(0, 0, 0, 0.3);
  }
  html.side-header-semi-transparent #header .header-body {
    background: transparent !important;
  }
}
@media (max-height: 768px) {
  html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    bottom: 0;
    top: auto;
  }
}
html[dir="rtl"] #header .header-search .btn-default {
  right: -35px;
}

/* Page Header */
.page-header {
  background-color: #171717;
  border-bottom: 5px solid #CCC;
  border-top: 5px solid #384045;
  margin: 0 0 35px 0;
  min-height: 50px;
  padding: 20px 0;
  position: relative;
  text-align: left;
}
.page-header .breadcrumb {
  background: none;
  margin: 0 0 2px 2px;
  padding: 0;
  position: relative;
  z-index: 1;
}
.page-header .breadcrumb > li {
  display: inline-block;
  font-size: 0.85em;
  text-transform: uppercase;
  text-shadow: none;
}
.page-header .breadcrumb > li + li:before {
  color: inherit;
  opacity: 0.5;
  font-family: FontAwesome;
  content: "\f105";
  padding: 0 7px 0 5px;
}
.page-header .breadcrumb.breadcrumb-valign-mid {
  position: absolute;
  top: 36%;
  right: 20px;
}
.page-header h1 {
  border-bottom: 5px solid #CCC;
  color: #fff;
  display: inline-block;
  font-size: 32px;
  font-weight: 200;
  line-height: 32px;
  margin: 0 0 -25px;
  padding: 0 0 25px;
  position: relative;
}
.page-header h1 span {
  color: #999da3;
  display: block;
  font-size: 0.6em;
  font-weight: 300;
  line-height: 1.3em;
  margin: 7px 0 0;
}

.page-header-no-title-border h1 {
  border: 0;
}

/* Page Header - Color */
.page-header-color {
  background-color: #F7F7F7;
  border-bottom: 5px solid #F4F4F4;
  border-top: none;
}
.page-header-color h1 {
  border-bottom: none;
  color: #1d2127;
  font-size: 2.3em;
  padding: 0;
  margin: 0;
}
.page-header-color h1 span {
  opacity: 0.65;
}
.page-header-color .breadcrumb > .active {
  opacity: 0.65;
}

/* Page Header - More Padding */
.page-header-more-padding {
  padding: 35px 0;
}

/* Page Header - Light */
.page-header-light {
  background-color: #F7F7F7;
  border-bottom: 5px solid #F4F4F4;
  border-top: none;
}
.page-header-light h1 {
  border-bottom: none;
  color: #1d2127;
  font-size: 2.3em;
}

/* Page Header - Reverse */
.page-header-reverse {
  text-align: right;
}
.page-header-reverse .breadcrumb, .page-header-reverse .breadcrumb.breadcrumb-valign-mid {
  float: left;
  left: 20px;
  right: auto;
}

/* Page Header - Parallax */
.page-header.parallax {
  margin: 0 0 35px;
  padding: 180px 0 60px;
}

.page-header-center {
  text-align: center;
}
.page-header-center .breadcrumb {
  margin: 15px 0 0;
}

/* Page Header Custom Background */
.page-header-custom-background {
  padding: 45px 0;
  background-repeat: repeat;
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
}
.page-header-custom-background h1 {
  color: #FFF;
  border: 0;
}
.page-header-custom-background h1 span {
  color: #FFF;
  opacity: 0.7;
}
.page-header-custom-background .breadcrumb.breadcrumb-valign-mid {
  top: 36%;
}
.page-header-custom-background .breadcrumb.breadcrumb-valign-mid li.active {
  color: #FFF;
  opacity: 0.7;
}
.page-header-custom-background .breadcrumb.breadcrumb-valign-mid a {
  color: #FFF;
}

/* Page Header Custom - Create your Own */
.page-header.custom-product {
  background-image: url(../img/no-image-available.png);
  background-repeat: repeat;
  background-color: #999;
  border-top: 5px solid #888;
  overflow: hidden;
}
.page-header.custom-product .row {
  position: relative;
}
.page-header.custom-product p.lead {
  color: #E8E8E8;
}
.page-header.custom-product img {
  bottom: -47px;
  position: relative;
}
.page-header.custom-product h1 {
  color: #FFF;
  margin-bottom: 10px;
  margin-top: 30px;
  border: 0;
}

/* Responsive */
@media (min-width: 992px) {
  .page-header-more-padding h1,
  .page-header-custom-background h1,
  .page-header-float-breadcrumb h1 {
    max-width: 70%;
  }
  .page-header-more-padding .breadcrumb-valign-mid,
  .page-header-custom-background .breadcrumb-valign-mid,
  .page-header-float-breadcrumb .breadcrumb-valign-mid {
    width: 30%;
    text-align: right;
  }
  .page-header-more-padding.page-header-reverse .breadcrumb-valign-mid,
  .page-header-custom-background.page-header-reverse .breadcrumb-valign-mid,
  .page-header-float-breadcrumb.page-header-reverse .breadcrumb-valign-mid {
    text-align: left;
  }
}
@media (max-width: 991px) {
  .page-header .breadcrumb {
    float: none !important;
    position: static !important;
  }
  .page-header .breadcrumb-valign-mid {
    margin-top: 15px;
  }

  .page-header-reverse .breadcrumb-valign-mid {
    margin-top: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 479px) {
  .page-header h1 {
    font-size: 26px;
    line-height: 26px;
  }
}
/* Parallax */
.parallax {
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: repeat;
  background-attachment: fixed;
  margin: 70px 0;
  padding: 50px 0;
  position: relative;
  clear: both;
}
.parallax blockquote {
  border: 0;
  padding: 0 10%;
}
.parallax blockquote i.fa-quote-left {
  font-size: 34px;
  position: relative;
  left: -5px;
}
.parallax blockquote p {
  font-size: 24px;
  line-height: 30px;
}
.parallax blockquote span {
  font-size: 16px;
  line-height: 20px;
  color: #999;
  position: relative;
  left: -5px;
}
.parallax.parallax-disabled {
  background-attachment: scroll !important;
  background-position: 50% 50% !important;
}

/* Section Featured */
section.section {
  background: #f4f4f4;
  border-top: 5px solid #f1f1f1;
  margin: 30px 0;
  padding: 50px 0;
}
section.section.section-no-background {
  background: transparent;
  border-top-color: transparent;
}
section.section.section-center {
  text-align: center;
}
section.section.section-text-light h1, section.section.section-text-light h2, section.section.section-text-light h3, section.section.section-text-light h4, section.section.section-text-light h5, section.section.section-text-light h6 {
  color: #FFF;
}
section.section.section-text-light p {
  color: #e6e6e6;
}
section.section.section-background {
  background-repeat: repeat;
  border: 0;
}
section.section.section-overlay {
  position: relative;
}
section.section.section-overlay:before {
  content: "";
  display: block;
  background: url("../img/no-image-available.png") repeat scroll 0 0 transparent;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
section.section.section-overlay-opacity {
  position: relative;
}
section.section.section-overlay-opacity:before {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-1:before {
  background: rgba(0, 0, 0, 0.1);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-2:before {
  background: rgba(0, 0, 0, 0.2);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-3:before {
  background: rgba(0, 0, 0, 0.3);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-4:before {
  background: rgba(0, 0, 0, 0.4);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-5:before {
  background: rgba(0, 0, 0, 0.5);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-6:before {
  background: rgba(0, 0, 0, 0.6);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-7:before {
  background: rgba(0, 0, 0, 0.7);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-8:before {
  background: rgba(0, 0, 0, 0.8);
}
section.section.section-overlay-opacity.section-overlay-opacity-scale-9:before {
  background: rgba(0, 0, 0, 0.9);
}
section.section.section-overlay-opacity-light:before {
  background: rgba(255, 255, 255, 0.5);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-1:before {
  background: rgba(255, 255, 255, 0.1);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-2:before {
  background: rgba(255, 255, 255, 0.2);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-3:before {
  background: rgba(255, 255, 255, 0.3);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-4:before {
  background: rgba(255, 255, 255, 0.4);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-5:before {
  background: rgba(255, 255, 255, 0.5);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-6:before {
  background: rgba(255, 255, 255, 0.6);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-7:before {
  background: rgba(255, 255, 255, 0.7);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-8:before {
  background: rgba(255, 255, 255, 0.8);
}
section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-9:before {
  background: rgba(255, 255, 255, 0.9);
}
section.section.section-default-scale-lighten {
  background-color: #fcfcfc !important;
  border-top-color: #fcfcfc !important;
}
section.section.section-video {
  background: none !important;
  border: 0;
}
section.section.section-parallax {
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: repeat;
  background-attachment: fixed;
  border: 0;
}
section.section.section-parallax.parallax-disabled {
  background-attachment: scroll !important;
  background-position: 50% 50% !important;
}
section.section.section-no-border {
  border: 0;
}
section.section.section-with-divider {
  margin: 56px 0 35px;
}
section.section.section-with-divider .divider {
  margin: -56px 0 44px;
}
section.section.section-footer {
  margin-bottom: -50px;
}
section.section.section-with-mockup {
  margin-top: 120px;
}
section.section.section-front {
  position: relative;
  z-index: 1;
}

/* Responsive */
@media (max-width: 991px) {
  .container-fluid > .row > .p-none section.section {
    margin-bottom: 0;
    margin-top: 0;
  }
  .container-fluid > .row > .p-none:first-child section.section {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .container-fluid > .row > .p-none:last-child section.section {
    margin-bottom: 30px;
    margin-top: 0;
  }
}
/* Sidebar */
aside ul.nav-list > li > a {
  color: #666;
  font-size: 0.9em;
  padding: 8px 0 8px 18px;
}

/* Home Slider - Revolution Slider */
.slider-container {
  background: #171717;
  height: 500px;
  overflow: hidden;
  width: 100%;
  direction: ltr;
}
.slider-container .tparrows {
  border-radius: 6px;
}
.slider-container .slider-single-slide .tparrows {
  display: none;
}
.slider-container .top-label {
  color: #FFF;
  font-size: 24px;
  font-weight: 300;
}
.slider-container .main-label {
  color: #FFF;
  font-size: 62px;
  line-height: 62px;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.slider-container .featured-label {
  color: #FFF;
  font-size: 52px;
  line-height: 52px;
  font-weight: 800;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}
.slider-container .bottom-label {
  color: #FFF;
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
}
.slider-container .blackboard-text {
  color: #BAB9BE;
  font-size: 46px;
  line-height: 46px;
  font-family: "Shadows Into Light", cursive;
  text-transform: uppercase;
}
.slider-container .btn-slider-action {
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  padding: 20px 25px;
}
.slider-container .top-label-dark, .slider-container .main-label-dark, .slider-container .bottom-label-dark {
  color: #333;
}
.slider-container .tp-caption-dark a {
  color: #333;
}
.slider-container .tp-caption-dark a:hover {
  color: #333;
}
.slider-container .tp-caption-overlay {
  background: #000;
  padding: 10px;
}
.slider-container .tp-caption-overlay-opacity {
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
}
.slider-container .tp-opacity-overlay {
  background: rgba(0, 0, 0, 0.75);
}
.slider-container.transparent {
  background-color: transparent;
}
.slider-container.light {
  background-color: #EAEAEA;
}
.slider-container.light .tp-bannershadow {
  opacity: 0.05;
  top: 0;
  bottom: auto;
  z-index: 1000;
}
.slider-container.light .featured-label, .slider-container.light .top-label, .slider-container.light .main-label, .slider-container.light .bottom-label {
  color: #333;
}
.slider-container.light .tp-caption a {
  color: #333;
}
.slider-container.light .tp-caption a:hover {
  color: #333;
}

/* Arrows */
.tparrows.tparrows-carousel {
  display: inline-block;
  position: absolute;
  width: 30px;
  height: auto;
  padding: 20px 0;
}
.tparrows.tparrows-carousel.tp-leftarrow {
  right: auto !important;
  left: -30px !important;
  border-radius: 0 4px 4px 0;
}
.tparrows.tparrows-carousel.tp-leftarrow:before {
  font-family: FontAwesome;
  content: "\f053";
  position: relative;
  left: -1px;
  top: 1px;
}
.tparrows.tparrows-carousel.tp-rightarrow {
  right: -60px !important;
  left: auto !important;
  border-radius: 4px 0 0 4px;
}
.tparrows.tparrows-carousel.tp-rightarrow:before {
  font-family: FontAwesome;
  content: "\f054";
  position: relative;
  left: 2px;
  top: 1px;
}

/* Slider IE Fix */
html.ie .forcefullwidth_wrapper_tp_banner {
  overflow: hidden;
}

/* Embed Border */
.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
  border: 0 !important;
}

/* Defaults */
.hesperiden-custom {
  padding: 10px 0 !important;
}
.hesperiden-custom .tp-tab {
  border: 0 !important;
}

/* Slider With Overlay */
.slider-with-overlay {
  position: relative;
}
.slider-with-overlay .slider-container {
  z-index: auto;
}

/* Slider Contact Form */
.slider-contact-form {
  margin: -45px 0 25px;
}
.slider-contact-form .featured-box {
  text-align: left;
}
.slider-contact-form label.error {
  display: none !important;
}
.slider-contact-form .alert {
  padding: 6px;
  text-align: center;
}
.slider-contact-form textarea {
  resize: none;
}

/* Responsive */
@media (min-width: 992px) {
  .slider-contact-form {
    margin: 0;
    position: absolute;
    top: 20px;
    z-index: 99;
    width: 100%;
  }
}
/* Boxed */
html.boxed .slider-container {
  width: 100% !important;
  left: auto !important;
}

/* Home Slider - Nivo Slider */
.nivo-slider {
  position: relative;
}
.nivo-slider .theme-default .nivoSlider {
  border-radius: 10px 10px 0 0;
  box-shadow: none;
  margin-top: 10px;
}
.nivo-slider .theme-default .nivoSlider img {
  border-radius: 10px 10px 0 0;
}
.nivo-slider .theme-default .nivo-controlNav {
  margin-top: -72px;
  position: absolute;
  z-index: 99;
  right: 5px;
  bottom: -17px;
}
.nivo-slider .theme-default .nivo-caption {
  padding-left: 20px;
}

/* Custom Map */
section.section-custom-map {
  background: transparent url(../img/no-image-available.png) center 0 no-repeat;
  padding: 129px 0 0;
  margin: 20px 0 0;
  border: 0;
}
section.section-custom-map section.section {
  border-top-color: rgba(241, 241, 241, 0.8);
  background: rgba(244, 244, 244, 0.8);
}

section.section-custom-map-2 {
  background: transparent url(../img/no-image-available.png) center 0 no-repeat;
  padding: 129px 0 0;
  margin: 50px 0 0;
  border: 0;
}
section.section-custom-map-2 section.section {
  border-top-color: rgba(241, 241, 241, 0.8);
  background: rgba(244, 244, 244, 0.8);
}

/* Home Intro */
.home-intro {
  background-color: #171717;
  margin-bottom: 60px;
  overflow: hidden;
  padding: 20px 0 10px 0;
  position: relative;
  text-align: left;
}
.home-intro p {
  color: #FFF;
  display: inline-block;
  font-size: 1.4em;
  font-weight: 300;
  max-width: 800px;
  padding-top: 5px;
}
.home-intro p span {
  color: #999;
  display: block;
  font-size: 0.8em;
  padding-top: 5px;
}
.home-intro p em {
  font-family: "Shadows Into Light", cursive;
  font-size: 1.6em;
}
.home-intro .get-started {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  text-align: right;
}
.home-intro .get-started .btn {
  position: relative;
  z-index: 1;
}
.home-intro .get-started .btn i {
  font-size: 1.1em;
  font-weight: normal;
  margin-left: 5px;
}
.home-intro .get-started a {
  color: #FFF;
}
.home-intro .get-started a:not(.btn) {
  color: #777;
}
.home-intro .learn-more {
  margin-left: 15px;
}
.home-intro.light {
  background-color: #E2E2E2;
}
.home-intro.light.secondary {
  background-color: #E0E1E4;
}
.home-intro.light p {
  color: #777;
}

/* Home Intro Compact */
.home-intro-compact {
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
}
.home-intro-compact p {
  padding-left: 20px;
}
.home-intro-compact .get-started {
  padding-right: 20px;
}

/* Responsive */
@media (max-width: 767px) {
  /* Home Intro */
  .home-intro-compact {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
/* Home Concept SVG Animations */
@keyframes animatedLineBg {
  from {
    stroke-dasharray: 1000;
  }
  to {
    stroke-dasharray: 1360;
  }
}
@keyframes animatedLineSmallCircle {
  from {
    stroke-dasharray: 1000;
  }
  to {
    stroke-dasharray: 1120;
  }
}
@keyframes animatedLineBigCircle {
  from {
    stroke-dasharray: 1000;
  }
  to {
    stroke-dasharray: 1266;
  }
}
@keyframes animatedInitialPin {
  0% {
    -webkit-transform: translate(2px, 69px) scale(0.23);
    -moz-transform: translate(2px, 69px) scale(0.23);
    -ms-transform: translate(2px, 69px) scale(0.23);
    -o-transform: translate(2px, 69px) scale(0.23);
    transform: translate(2px, 69px) scale(0.23);
  }
  33% {
    -webkit-transform: translate(2px, 79px) scale(0.23);
    -moz-transform: translate(2px, 79px) scale(0.23);
    -ms-transform: translate(2px, 79px) scale(0.23);
    -o-transform: translate(2px, 79px) scale(0.23);
    transform: translate(2px, 79px) scale(0.23);
  }
  66% {
    -webkit-transform: translate(2px, 74px) scale(0.23);
    -moz-transform: translate(2px, 74px) scale(0.23);
    -ms-transform: translate(2px, 74px) scale(0.23);
    -o-transform: translate(2px, 74px) scale(0.23);
    transform: translate(2px, 74px) scale(0.23);
  }
  100% {
    -webkit-transform: translate(2px, 79px) scale(0.23);
    -moz-transform: translate(2px, 79px) scale(0.23);
    -ms-transform: translate(2px, 79px) scale(0.23);
    -o-transform: translate(2px, 79px) scale(0.23);
    transform: translate(2px, 79px) scale(0.23);
  }
}
@keyframes animatedFinalPin {
  0% {
    -webkit-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
    -moz-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
    -ms-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
    -o-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
    transform: translate(325px, -19px) scale(0.23) rotate(36deg);
  }
  33% {
    -webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    -moz-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    -ms-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    -o-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  }
  66% {
    -webkit-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
    -moz-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
    -ms-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
    -o-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
    transform: translate(325px, -16px) scale(0.23) rotate(36deg);
  }
  100% {
    -webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    -moz-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    -ms-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    -o-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
    transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  }
}
/* Firefox Final Pin Animation */
@keyframes animatedFinalPinFirefox {
  0% {
    -webkit-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
    -moz-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
    -ms-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
    -o-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
    transform: translate(193px, -16px) scale(0.23) rotate(36deg);
  }
  33% {
    -webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    -moz-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    -ms-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    -o-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    transform: translate(193px, -10px) scale(0.23) rotate(36deg);
  }
  66% {
    -webkit-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
    -moz-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
    -ms-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
    -o-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
    transform: translate(193px, -13px) scale(0.23) rotate(36deg);
  }
  100% {
    -webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    -moz-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    -ms-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    -o-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
    transform: translate(193px, -10px) scale(0.23) rotate(36deg);
  }
}
/* Home Concept */
.home-concept {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.home-concept svg.home-concept-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
  -moz-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  -o-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}
.home-concept svg.home-concept-bg .line {
  stroke-dashoffset: 1000;
}
.home-concept svg.home-concept-bg .line.animated-line {
  animation-name: animatedLineBg;
  animation-timing-function: linear;
}
.home-concept svg.home-concept-bg .initial-pin {
  -webkit-transform: translate(2px, 79px) scale(0.23);
  -moz-transform: translate(2px, 79px) scale(0.23);
  -ms-transform: translate(2px, 79px) scale(0.23);
  -o-transform: translate(2px, 79px) scale(0.23);
  transform: translate(2px, 79px) scale(0.23);
}
.home-concept svg.home-concept-bg .initial-pin.animated-pin {
  animation-name: animatedInitialPin;
}
.home-concept svg.home-concept-bg .final-pin {
  -webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  -moz-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  -ms-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  -o-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  transform: translate(325px, -13px) scale(0.23) rotate(36deg);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}
.home-concept svg.home-concept-bg .final-pin.animated-pin {
  animation-name: animatedFinalPin;
}
.home-concept strong {
  display: block;
  font-family: "Shadows Into Light", cursive;
  font-size: 2.1em;
  font-weight: normal;
  position: relative;
  margin-top: 30px;
}
.home-concept .row {
  position: relative;
}
.home-concept .process-image {
  width: 160px;
  margin: 135px auto 0 auto;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}
.home-concept .process-image .box-image {
  position: relative;
}
.home-concept .process-image .box-image .small-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-concept .process-image .box-image .small-circle .line {
  stroke-dashoffset: 1000;
  -webkit-transform: rotate(168deg);
  -moz-transform: rotate(168deg);
  -ms-transform: rotate(168deg);
  -o-transform: rotate(168deg);
  transform: rotate(168deg);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}
.home-concept .process-image .box-image .small-circle .line.animated-line {
  animation-name: animatedLineSmallCircle;
  animation-timing-function: linear;
}
.home-concept .process-image .box-image img {
  position: relative;
  border-radius: 150px;
  margin: 7px 8px;
  width: auto;
  height: auto;
  max-width: 145px;
  max-height: 145px;
  z-index: 1;
}
.home-concept .our-work {
  margin-top: 52px;
  font-size: 2.6em;
}
.home-concept .project-image {
  width: 350px;
  margin: 15px 0 0 -30px;
  padding-bottom: 45px;
  position: relative;
  z-index: 1;
}
.home-concept .project-image .box-image {
  position: relative;
}
.home-concept .project-image .box-image .big-circle {
  position: absolute;
  top: calc(50% + 6px);
  left: 50%;
  width: 106%;
  height: 106%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-concept .project-image .box-image .big-circle .line {
  stroke-dashoffset: 1000;
  -webkit-transform: rotate(171deg);
  -moz-transform: rotate(171deg);
  -ms-transform: rotate(171deg);
  -o-transform: rotate(171deg);
  transform: rotate(171deg);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}
.home-concept .project-image .box-image .big-circle .line.animated-line {
  animation-name: animatedLineBigCircle;
  animation-timing-function: linear;
}
.home-concept .sun {
  background: transparent url(../img/no-image-available.png) no-repeat 0 0;
  width: 60px;
  height: 56px;
  display: block;
  position: absolute;
  left: 10%;
  top: 35px;
}
.home-concept .cloud {
  background: transparent url(../img/no-image-available.png) no-repeat 100% 0;
  width: 116px;
  height: 56px;
  display: block;
  position: absolute;
  left: 57%;
  top: 35px;
}

/* Home Concept - Firefox Adjusts */
html.gecko .home-concept svg.home-concept-bg .final-pin.animated-pin {
  animation-name: animatedFinalPinFirefox;
}

/* Home Concept - Internet Explorer Adjusts */
html.ie .home-concept {
  background: transparent url(../img/no-image-available.png) no-repeat center 0;
}
html.ie .home-concept svg {
  display: none;
}
html.ie .home-concept .process-image {
  background: transparent url(../img/no-image-available.png) no-repeat 0 0;
}
html.ie .home-concept .project-image {
  background: transparent url(../img/no-image-available.png) no-repeat 100% 0;
}

/* Responsive */
@media (max-width: 991px) {
  .home-concept {
    background: none;
  }
  .home-concept svg.home-concept-bg {
    display: none;
  }
  .home-concept .project-image {
    margin: 20px auto 0 auto;
  }
  .home-concept .process-image {
    margin-top: 0;
    padding-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .home-concept .project-image {
    background-position: 0 0;
    margin-left: auto;
    max-width: 160px;
  }
  .home-concept .fc-slideshow {
    max-width: 145px;
    height: 145px;
  }
  .home-concept .fc-slideshow img {
    max-height: 145px;
    max-width: 145px;
  }
}
/* Page 404 */
.page-not-found {
  margin: 50px 0;
}
.page-not-found h2 {
  font-size: 140px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 140px;
}
.page-not-found h4 {
  color: #777;
}
.page-not-found p {
  font-size: 1.4em;
  line-height: 36px;
}

/* Responsive */
@media (max-width: 479px) {
  .page-not-found {
    margin: 0;
  }
  .page-not-found h2 {
    font-size: 100px;
    letter-spacing: 0;
    line-height: 100px;
  }
}
.text-xs {
  font-size: 1rem !important;
}

.text-sm {
  font-size: 1.3rem !important;
}

.text-md {
  font-size: 1.6rem !important;
}

.text-lg {
  font-size: 1.9rem !important;
}

.text-xl {
  font-size: 2.2rem !important;
}

.text-muted {
  color: #999 !important;
}

html.dark .text-muted {
  color: #505461 !important;
}

.text-dark {
  color: #1d2127 !important;
}

.text-light {
  color: #FFF !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 900 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.rounded {
  border-radius: 5px !important;
}

.b-thin {
  border-width: 3px !important;
}

.b-normal {
  border-width: 5px !important;
}

.b-thick {
  border-width: 7px !important;
}

/* Spacements */
/* spacement top & bottom */
.m-none {
  margin: 0 !important;
}

.m-auto {
  margin: 0 auto !important;
}

.m-xs {
  margin: 5px !important;
}

.m-sm {
  margin: 10px !important;
}

.m-md {
  margin: 15px !important;
}

.m-lg {
  margin: 20px !important;
}

.m-xl {
  margin: 25px !important;
}

.m-xlg {
  margin: 30px !important;
}

/* spacement top  */
.mt-none {
  margin-top: 0 !important;
}

.mt-xs {
  margin-top: 5px !important;
}

.mt-sm {
  margin-top: 10px !important;
}

.mt-md {
  margin-top: 15px !important;
}

.mt-lg {
  margin-top: 20px !important;
}

.mt-xl {
  margin-top: 25px !important;
}

.mt-xlg {
  margin-top: 30px !important;
}

/* spacement bottom  */
.mb-none {
  margin-bottom: 0 !important;
}

.mb-xs {
  margin-bottom: 5px !important;
}

.mb-sm {
  margin-bottom: 10px !important;
}

.mb-md {
  margin-bottom: 15px !important;
}

.mb-lg {
  margin-bottom: 20px !important;
}

.mb-xl {
  margin-bottom: 25px !important;
}

.mb-xlg {
  margin-bottom: 30px !important;
}

/* spacement left  */
.ml-none {
  margin-left: 0 !important;
}

.ml-xs {
  margin-left: 5px !important;
}

.ml-sm {
  margin-left: 10px !important;
}

.ml-md {
  margin-left: 15px !important;
}

.ml-lg {
  margin-left: 20px !important;
}

.ml-xl {
  margin-left: 25px !important;
}

.ml-xlg {
  margin-left: 30px !important;
}

/* spacement right  */
.mr-none {
  margin-right: 0 !important;
}

.mr-xs {
  margin-right: 5px !important;
}

.mr-sm {
  margin-right: 10px !important;
}

.mr-md {
  margin-right: 15px !important;
}

.mr-lg {
  margin-right: 20px !important;
}

.mr-xl {
  margin-right: 25px !important;
}

.mr-xlg {
  margin-right: 30px !important;
}

/* Spacement Padding */
.p-none {
  padding: 0 !important;
}

.p-xs {
  padding: 5px !important;
}

.p-sm {
  padding: 10px !important;
}

.p-md {
  padding: 15px !important;
}

.p-lg {
  padding: 20px !important;
}

.p-xl {
  padding: 25px !important;
}

.p-xlg {
  padding: 30px !important;
}

/* spacement top  */
.pt-none {
  padding-top: 0 !important;
}

.pt-xs {
  padding-top: 5px !important;
}

.pt-sm {
  padding-top: 10px !important;
}

.pt-md {
  padding-top: 15px !important;
}

.pt-lg {
  padding-top: 20px !important;
}

.pt-xl {
  padding-top: 25px !important;
}

.pt-xlg {
  padding-top: 30px !important;
}

/* spacement bottom  */
.pb-none {
  padding-bottom: 0 !important;
}

.pb-xs {
  padding-bottom: 5px !important;
}

.pb-sm {
  padding-bottom: 10px !important;
}

.pb-md {
  padding-bottom: 15px !important;
}

.pb-lg {
  padding-bottom: 20px !important;
}

.pb-xl {
  padding-bottom: 25px !important;
}

.pb-xlg {
  padding-bottom: 30px !important;
}

/* spacement left  */
.pl-none {
  padding-left: 0 !important;
}

.pl-xs {
  padding-left: 5px !important;
}

.pl-sm {
  padding-left: 10px !important;
}

.pl-md {
  padding-left: 15px !important;
}

.pl-lg {
  padding-left: 20px !important;
}

.pl-xl {
  padding-left: 25px !important;
}

.pl-xlg {
  padding-left: 30px !important;
}

/* spacement right  */
.pr-none {
  padding-right: 0 !important;
}

.pr-xs {
  padding-right: 5px !important;
}

.pr-sm {
  padding-right: 10px !important;
}

.pr-md {
  padding-right: 15px !important;
}

.pr-lg {
  padding-right: 20px !important;
}

.pr-xl {
  padding-right: 25px !important;
}

.pr-xlg {
  padding-right: 30px !important;
}

.ib {
  display: inline-block;
  vertical-align: top;
}

.va-middle {
  vertical-align: middle;
}

.ws-nowrap {
  white-space: nowrap;
}

.ws-normal {
  white-space: normal;
}

.col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5, .col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5, .col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5, .col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1-5 {
  width: 20%;
  float: left;
}

.col-xs-2-5 {
  width: 40%;
  float: left;
}

.col-xs-3-5 {
  width: 60%;
  float: left;
}

.col-xs-4-5 {
  width: 80%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }

  col-sm-2-5 {
    width: 40%;
    float: left;
  }

  col-sm-3-5 {
    width: 60%;
    float: left;
  }

  col-sm-4-5 {
    width: 80%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }

  .col-md-2-5 {
    width: 40%;
    float: left;
  }

  .col-md-3-5 {
    width: 60%;
    float: left;
  }

  .col-md-4-5 {
    width: 80%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }

  .col-lg-2-5 {
    width: 40%;
    float: left;
  }

  .col-lg-3-5 {
    width: 60%;
    float: left;
  }

  .col-lg-4-5 {
    width: 80%;
    float: left;
  }
}
@media (max-width: 991px) {
  .col-md-3 .sidebar {
    clear: both;
  }
}
/* #Footer */
#footer {
  background: #0e0e0e;
  border-top: 4px solid #0e0e0e;
  font-size: 0.9em;
  margin-top: 50px;
  padding: 70px 0 0;
  position: relative;
  clear: both;
}
#footer .container .row > div {
  margin-bottom: 25px;
}
#footer .container input:focus {
  box-shadow: none;
}
#footer a:hover {
  color: #CCC;
}
#footer h1, #footer h2, #footer h3, #footer h4, #footer a {
  color: #FFF;
}
#footer h4 {
  font-size: 1.8em;
  font-weight: 200;
}
#footer h5 {
  color: #CCC;
}
#footer ul.nav-list > li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 20px;
  padding-bottom: 11px;
}
#footer ul.nav-list > li:last-child a {
  border-bottom: 0;
}
#footer ul.nav-list > li a:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
#footer ul.list.icons li {
  margin-bottom: 5px;
}
#footer form {
  opacity: 0.85;
}
#footer form label.error {
  line-height: 16px;
  margin: 5px 0 -5px;
  display: block;
  clear: both;
}
#footer form .alert {
  padding: 6px;
  text-align: center;
}
#footer .footer-ribbon {
  background: #999;
  position: absolute;
  margin: -90px 0 0 14px;
  padding: 10px 20px 6px 20px;
}
#footer .footer-ribbon:before {
  border-right: 10px solid #646464;
  border-top: 16px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: -10px;
  position: absolute;
  top: 0;
  width: 7px;
}
#footer .footer-ribbon span {
  color: #FFF;
  font-size: 1.6em;
  font-family: "Shadows Into Light", cursive;
}
#footer .newsletter form {
  width: 100%;
  max-width: 262px;
}
#footer .newsletter form input.form-control {
  height: 34px;
  padding: 6px 12px;
}
#footer .alert a {
  color: #222;
}
#footer .alert a:hover {
  color: #666;
}
#footer .twitter .fa, #footer .twitter .icons {
  clear: both;
  font-size: 1.5em;
  position: relative;
  top: 3px;
}
#footer .twitter .meta {
  color: #999;
  display: block;
  font-size: 0.9em;
  padding-top: 3px;
}
#footer .twitter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer .twitter ul li {
  padding-bottom: 20px;
}
#footer .twitter ul li:last-child {
  padding-bottom: 0;
}
#footer .twitter-account {
  color: #FFF;
  display: block;
  font-size: 0.9em;
  margin: -15px 0 5px;
  opacity: 0.55;
}
#footer .twitter-account:hover {
  opacity: 1;
}
#footer .contact {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer .contact i {
  display: block;
  font-size: 1.2em;
  margin: 7px 8px 10px 0;
  position: relative;
  text-align: center;
  top: -2px;
  width: 16px;
}
#footer .contact i.fa-envelope {
  font-size: 1em;
  top: 0;
}
#footer .contact p {
  margin-bottom: 10px;
}
#footer .contact p.contact-value {
  padding-left: 30px;
}
#footer .logo {
  display: block;
}
#footer .logo.mb-xl {
  margin-bottom: 12px;
}
#footer .footer-copyright {
  background: #060606;
  border-top: 4px solid #060606;
  margin-top: 40px;
  padding: 30px 0 10px;
}
#footer .footer-copyright nav {
  float: right;
}
#footer .footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer .footer-copyright nav ul li {
  border-left: 1px solid #505050;
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}
#footer .footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}
#footer .footer-copyright p {
  color: #555;
  margin: 0;
  padding: 0;
}
#footer .phone {
  color: #FFF;
  font-size: 26px;
  font-weight: bold;
  display: block;
  padding-bottom: 15px;
  margin-top: -5px;
}
#footer .narrow-phone {
  text-align: right;
  margin: -2px 0 0 0;
}
#footer .narrow-phone .phone {
  font-size: 16px;
  padding-bottom: 0;
  margin-top: -13px;
}
#footer .recent-work {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .recent-work li {
  float: left;
  width: 30%;
  margin: 10px 3% 0 0;
}
#footer .recent-work img {
  border: 0;
  width: 100%;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
}
#footer .recent-work .img-thumbnail-hover-icon {
  border: 0;
}
#footer .recent-work .img-thumbnail-hover-icon:after {
  font-size: 22px;
  margin-left: -2px;
}
#footer .view-more-recent-work {
  padding: 12px;
}
#footer.short {
  padding-top: 50px;
}
#footer.short .footer-copyright {
  background: transparent;
  border: 0;
  padding-top: 0;
  margin-top: 0;
}
#footer.light {
  padding-top: 60px;
  background: #FFF;
  border-top: 1px solid #DDD;
}
#footer.light .footer-ribbon {
  margin-top: -76px;
  margin-left: 14px;
}
#footer.light .footer-copyright {
  margin-top: 15px;
  background: #FFF;
  border-top: 1px solid #DDD;
}
#footer.light .footer-copyright p {
  color: #777;
}
#footer.light .phone,
#footer.light .twitter-account {
  color: #777;
}
#footer.light ul.nav-pills > li.active > a {
  color: #FFF;
}
#footer.narrow {
  padding-top: 30px;
}
#footer.narrow .footer-copyright {
  margin-top: 0;
}
#footer.narrow .nav > li > a {
  padding: 5px 12px;
}
#footer.color {
  color: #FFF;
}
#footer.color p {
  color: #FFF;
}
#footer.color .footer-copyright nav ul li {
  border-left-color: #FFF;
}

/* Responsive */
@media (max-width: 991px) {
  #footer .phone {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  /* Footer */
  #footer .phone {
    font-size: 20px;
  }
}
/* Dark Version */
html.dark body {
  background-color: #1d2127;
}
html.dark.boxed .body {
  background-color: #1d2127;
}
html.dark #header .header-top {
  background: #282d36;
  border-bottom-color: #2e353e;
}
html.dark #header .header-search .form-control {
  background: #21262d;
}
html.dark #header .header-body {
  background: #1d2127;
  border-top-color: #2e353e;
}
html.dark #header .header-nav-bar {
  background-color: #21262d;
}
html.dark #header .header-nav-top .nav > li > a:hover, html.dark #header .header-nav-top .nav > li > a a:focus {
  background-color: #21262d;
}
html.dark #header .header-nav-top .dropdown-menu {
  background: #21262d;
  border-color: #21262d;
}
html.dark #header .header-nav-top .dropdown-menu a:hover, html.dark #header .header-nav-top .dropdown-menu a:focus {
  background: #282d36 !important;
}
html.dark.sticky-header-active #header .header-body {
  border-bottom-color: #2e353e;
}
html.dark #header.header-transparent .header-body {
  background: transparent;
}
html.dark #header.header-transparent .header-body:before {
  background-color: #1d2127;
  border-bottom-color: #2e353e;
}
html.dark #header.header-semi-transparent .header-body {
  background: transparent;
}
html.dark #header.header-semi-transparent .header-body:before {
  background-color: #1d2127;
  border-bottom-color: #2e353e;
}
html.dark #header.header-semi-transparent-light .header-body {
  background: transparent;
}
html.dark #header.header-semi-transparent-light .header-body:before {
  background-color: #1d2127;
  border-bottom-color: #2e353e;
}
html.dark h1, html.dark h2, html.dark h3, html.dark h4, html.dark h5, html.dark h6 {
  color: #FFF;
}
html.dark .alert h1, html.dark .alert h2, html.dark .alert h3, html.dark .alert h4, html.dark .alert h5, html.dark .alert h6 {
  color: #111;
}
html.dark blockquote {
  border-color: #282d36;
}
html.dark section.featured {
  background-color: #191c21;
  border-top-color: #2e353e;
}
html.dark section.section {
  background-color: #21262d;
  border-top-color: #282d36;
}
html.dark .dropdown-menu > li > a {
  color: #FFF;
}
html.dark .dropdown-menu > li > a:hover, html.dark .dropdown-menu > li > a:focus {
  background-color: #2e353e;
}
html.dark .tabs .nav-tabs li a,
html.dark .tabs .nav-tabs li a:focus {
  border-top-color: #282d36;
  border-left-color: #282d36;
  border-right-color: #282d36;
  background: #282d36;
}
html.dark .tabs .nav-tabs li a:hover {
  border-top-color: #808697;
}
html.dark .tabs .nav-tabs li.active a,
html.dark .tabs .nav-tabs li.active a:hover,
html.dark .tabs .nav-tabs li.active a:focus {
  border-top-color: #808697;
}
html.dark .tabs .nav-tabs.nav-justified {
  border-left-width: 0;
  border-right-width: 0;
  border-left-color: transparent;
  border-right-color: transparent;
}
html.dark .tabs .nav-tabs.nav-justified li a,
html.dark .tabs .nav-tabs.nav-justified li a:hover,
html.dark .tabs .nav-tabs.nav-justified li a:focus {
  border-bottom-color: #282d36;
}
html.dark .tabs.tabs-left .nav-tabs > li a, html.dark .tabs.tabs-right .nav-tabs > li a {
  background: #282d36;
  border-left-color: #282d36;
  border-right-color: #282d36;
}
html.dark .tabs.tabs-left .nav-tabs > li:last-child a, html.dark .tabs.tabs-right .nav-tabs > li:last-child a {
  border-bottom-color: #282d36;
}
html.dark .tabs .nav-tabs {
  border-color: #282d36;
}
html.dark .tabs .nav-tabs li.active a,
html.dark .tabs .nav-tabs li.active a:hover,
html.dark .tabs .nav-tabs li.active a:focus,
html.dark .tabs .nav-tabs.nav-justified li.active a,
html.dark .tabs .nav-tabs.nav-justified li.active a:hover,
html.dark .tabs .nav-tabs.nav-justified li.active a:focus {
  background: #2e353e;
  border-left-color: #2e353e;
  border-right-color: #2e353e;
}
html.dark .tabs .nav-tabs.nav-justified li.active a {
  border-bottom-color: #2e353e;
}
html.dark .tabs.tabs-vertical {
  border-top-color: #2e353e;
}
html.dark .tabs.tabs-bottom .nav-tabs li a,
html.dark .tabs.tabs-bottom .nav-tabs li a:focus {
  border-bottom-color: #282d36;
  border-top-color: #2e353e;
}
html.dark .tabs.tabs-bottom .nav-tabs li a:hover {
  border-bottom-color: #808697;
  border-top-color: #2e353e;
}
html.dark .tabs.tabs-bottom .nav-tabs li.active a,
html.dark .tabs.tabs-bottom .nav-tabs li.active a:hover,
html.dark .tabs.tabs-bottom .nav-tabs li.active a:focus {
  border-bottom-color: #808697;
  border-top-color: #2e353e;
}
html.dark .tabs .tab-content {
  background: #2e353e;
  border-color: #2e353e;
}
html.dark .tabs-primary.tabs-bottom .nav-tabs li a,
html.dark .tabs-primary.tabs-bottom .nav-tabs li a:hover,
html.dark .tabs-primary.tabs-bottom .nav-tabs li a:focus, html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a,
html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:focus {
  border-top-color: #2e353e !important;
}
html.dark .nav-tabs li.active a,
html.dark .nav-tabs li.active a:hover,
html.dark .nav-tabs li.active a:focus,
html.dark .nav-tabs li a {
  color: #808697;
}
html.dark .tab-content {
  background: #2e353e;
  border-color: #1d2127;
}
html.dark .tabs-simple .tab-content,
html.dark .tabs-simple .nav-tabs li a,
html.dark .tabs-simple .nav-tabs li.active a {
  background: transparent !important;
}
html.dark .nav > li > a:hover,
html.dark .nav > li > a:focus {
  background-color: #2e353e;
}
html.dark .call-to-action.call-to-action-default {
  background: #282d36;
}
html.dark .call-to-action.call-to-action-dark {
  background: #2e353e;
}
html.dark .call-to-action.with-borders {
  border-top-color: #2e353e;
  border-bottom-color: #2e353e;
  border-left-color: #282d36;
  border-right-color: #282d36;
}
html.dark .call-to-action.with-full-borders {
  border-color: #2e353e;
}
html.dark .call-to-action.featured {
  background: -webkit-linear-gradient( top , #282d36 1%, #2e353e 98%) repeat scroll 0 0 transparent;
  border-bottom-color: #2e353e;
  border-left-color: #282d36;
  border-right-color: #282d36;
}
html.dark .call-to-action.call-to-action-in-footer:before {
  border-top-color: #1d2127;
}
html.dark .counters.with-borders .counter {
  border-top: 1px solid #2e353e;
  border-bottom: 1px solid #2e353e;
  border-left: 1px solid #282d36;
  border-right: 1px solid #282d36;
}
html.dark .counters.counters-text-dark .counter {
  color: #FFF !important;
}
html.dark .counters.counters-text-dark .counter .fa, html.dark .counters.counters-text-dark .counter .icons, html.dark .counters.counters-text-dark .counter strong, html.dark .counters.counters-text-dark .counter label {
  color: #FFF !important;
}
html.dark section.timeline:after {
  background: #505050;
  background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(100%, #7db9e8));
  background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
  background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
  background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
  background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
}
html.dark section.timeline .timeline-date {
  background-color: #282d36;
  border-color: #282d36;
  text-shadow: none;
}
html.dark section.timeline .timeline-title {
  background: #282d36;
}
html.dark section.timeline .timeline-box {
  border-color: #282d36;
  background: #282d36;
}
html.dark section.timeline .timeline-box.left:before {
  box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
}
html.dark section.timeline .timeline-box.left:after {
  background: #282d36;
  border-right-color: #282d36;
  border-top-color: #282d36;
}
html.dark section.timeline .timeline-box.right:before {
  box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
}
html.dark section.timeline .timeline-box.right:after {
  background: #282d36;
  border-left-color: #282d36;
  border-bottom-color: #282d36;
}
html.dark .form-control {
  background-color: #282d36;
  border-color: #282d36;
}
html.dark .btn-default {
  background-color: #282d36;
  border-color: #282d36;
  color: #FFF;
}
html.dark .btn.disabled, html.dark .btn.disabled:hover, html.dark .btn.disabled:active, html.dark .btn.disabled:focus,
html.dark .btn[disabled],
html.dark .btn[disabled]:hover,
html.dark .btn[disabled]:active,
html.dark .btn[disabled]:focus {
  border-color: #282d36 !important;
}
html.dark .pagination > li > a,
html.dark .pagination > li > span {
  background-color: #282d36;
  border-color: #242830;
}
html.dark .theme-default .nivoSlider {
  background-color: #1d2127;
}
html.dark section.section-custom-map {
  background: transparent url(../img/no-image-available.png) center 0 no-repeat;
}
html.dark section.section-custom-map section.section {
  border-top-color: rgba(40, 45, 54, 0.8);
  background: rgba(33, 38, 45, 0.8);
}
html.dark .home-concept {
  background-image: url(../img/no-image-available.png);
}
html.dark .home-concept .process-image {
  background-image: url(../img/no-image-available.png);
}
html.dark .home-concept .project-image {
  background-image: url(../img/no-image-available.png);
}
html.dark .home-concept .sun {
  background-image: url(../img/no-image-available.png);
}
html.dark .home-concept .cloud {
  background-image: url(../img/no-image-available.png);
}
html.dark .page-header {
  border-bottom-color: #191c21;
}
html.dark .page-header-light {
  background-color: #2e353e;
}
html.dark .panel-group .panel {
  background-color: #282d36;
}
html.dark .panel-group .panel-default {
  border-color: #282d36;
}
html.dark .panel-group .panel-heading {
  background-color: #2e353e;
}
html.dark .panel-group .form-control {
  background-color: #21262d;
  border-color: #21262d;
}
html.dark .toggle > label {
  background-color: #2e353e;
}
html.dark .toggle.toggle-simple .toggle > label {
  color: #FFF;
}
html.dark .featured-box {
  background: #2e353e;
  border-left-color: #2e353e;
  border-right-color: #2e353e;
  border-bottom-color: #2e353e;
}
html.dark .featured-box.secondary h4 {
  color: #FFF;
}
html.dark .featured-boxes-flat .featured-box .box-content {
  background: #2e353e;
}
html.dark .featured-boxes-style-5 .featured-box .box-content h4, html.dark .featured-boxes-style-6 .featured-box .box-content h4, html.dark .featured-boxes-style-7 .featured-box .box-content h4 {
  color: #FFF;
}
html.dark .featured-boxes-style-2 .featured-box {
  background: transparent;
}
html.dark .featured-boxes-style-3 .featured-box .icon-featured {
  background: #1d2127;
}
html.dark .featured-boxes-style-4 .featured-box {
  background: transparent;
}
html.dark .featured-boxes-style-5 .featured-box {
  background: transparent;
}
html.dark .featured-boxes-style-5 .featured-box .icon-featured {
  background: #282d36;
  border-color: #2e353e;
}
html.dark .featured-boxes-style-6 .featured-box {
  background: transparent;
}
html.dark .featured-boxes-style-6 .featured-box .icon-featured {
  background: #282d36;
  border-color: #2e353e;
}
html.dark .featured-boxes-style-6 .featured-box .icon-featured:after {
  border-color: #2e353e;
}
html.dark .featured-boxes-style-7 .featured-box {
  background: transparent;
}
html.dark .featured-boxes-style-7 .featured-box .icon-featured {
  background: #282d36;
  border-color: #2e353e;
}
html.dark .featured-boxes-style-7 .featured-box .icon-featured:after {
  box-shadow: 3px 3px #161a1e;
}
html.dark .featured-boxes-style-8 .featured-box .icon-featured {
  background: #2e353e;
}
html.dark .featured-box-effect-1 .icon-featured:after {
  box-shadow: 0 0 0 3px #2e353e;
}
html.dark .feature-box.feature-box-style-2 h4, html.dark .feature-box.feature-box-style-3 h4, html.dark .feature-box.feature-box-style-4 h4 {
  color: #FFF;
}
html.dark .feature-box.feature-box-style-6 .feature-box-icon {
  border-color: #2e353e;
}
html.dark .feature-box.feature-box-style-6 .feature-box-icon:after {
  border-color: #2e353e;
}
html.dark .owl-dots .owl-dot span {
  background: #2e353e;
}
html.dark .owl-carousel.top-border {
  border-top-color: #3F4247;
}
html.dark .progress {
  background: #2e353e;
}
html.dark .arrow {
  background-image: url(../img/no-image-available.png);
}
html.dark .thumbnail,
html.dark .img-thumbnail,
html.dark .thumb-info {
  background-color: #282d36;
  border-color: #282d36;
}
html.dark .thumb-info .thumb-info-wrapper:after {
  background-color: rgba(29, 33, 39, 0.9);
}
html.dark .thumb-info-social-icons {
  border-top-color: #373f4a;
}
html.dark ul.nav-list > li a {
  border-bottom-color: #282d36;
}
html.dark ul.nav-list > li a:hover {
  background-color: #282d36;
}
html.dark .content-grid .content-grid-item:before {
  border-left-color: #2e353e;
}
html.dark .content-grid .content-grid-item:after {
  border-bottom-color: #2e353e;
}
html.dark .content-grid.content-grid-dashed .content-grid-item:before {
  border-left-color: #2e353e;
}
html.dark .content-grid.content-grid-dashed .content-grid-item:after {
  border-bottom-color: #2e353e;
}
html.dark .testimonial .testimonial-author strong {
  color: #FFF;
}
html.dark .testimonial.testimonial-style-3 blockquote {
  background: #2e353e !important;
}
html.dark .testimonial.testimonial-style-3 .testimonial-arrow-down {
  border-top-color: #2e353e !important;
}
html.dark .testimonial.testimonial-style-4 {
  border-color: #2e353e !important;
}
html.dark .testimonial.testimonial-style-5 .testimonial-author {
  border-top-color: #2e353e !important;
}
html.dark .popover {
  background-color: #2e353e;
  border: 1px solid #282d36;
}
html.dark .popover.top > .arrow {
  border-top-color: #282d36;
}
html.dark .popover.top > .arrow:after {
  border-top-color: #2e353e;
}
html.dark .popover.right > .arrow {
  border-right-color: #282d36;
}
html.dark .popover.right > .arrow:after {
  border-right-color: #2e353e;
}
html.dark .popover.bottom > .arrow {
  border-bottom-color: #282d36;
}
html.dark .popover.bottom > .arrow:after {
  border-bottom-color: #2e353e;
}
html.dark .popover.left > .arrow {
  border-left-color: #282d36;
}
html.dark .popover.left > .arrow:after {
  border-left-color: #2e353e;
}
html.dark .popover-title {
  background-color: #282d36;
  border-bottom: #2e353e;
}
html.dark .page-header {
  border-bottom-color: #282d36;
}
html.dark .table > thead > tr > th,
html.dark .table > tbody > tr > th,
html.dark .table > tfoot > tr > th,
html.dark .table > thead > tr > td,
html.dark .table > tbody > tr > td,
html.dark .table > tfoot > tr > td,
html.dark .table-bordered {
  border-color: #282d36;
}
html.dark .table-striped > tbody > tr:nth-child(2n+1) > td,
html.dark .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #2e353e;
}
html.dark pre {
  background-color: #282d36;
  border-color: #282d36;
  color: #777;
}
html.dark .show-grid [class*="col-md-"] .show-grid-block {
  background-color: #282d36;
  border-color: #282d36;
}
html.dark .google-map-borders,
html.dark .embed-responsive-borders {
  border-color: #2e353e;
}
html.dark .alert.alert-default {
  border-color: #2e353e;
  background-color: #282d36;
}
html.dark hr {
  background-image: -webkit-linear-gradient(left, transparent, #3F4247, transparent);
  background-image: linear-gradient(to right, transparent, #3F4247, transparent);
}
html.dark hr.light {
  background-image: -webkit-linear-gradient(left, transparent, #3F4247, transparent);
  background-image: linear-gradient(to right, transparent, #3F4247, transparent);
}
html.dark hr.solid {
  background: #3F4247;
}
html.dark .divider {
  background-image: -webkit-linear-gradient(left, transparent, #3F4247, transparent);
  background-image: linear-gradient(to right, transparent, #3F4247, transparent);
}
html.dark .divider .fa, html.dark .divider .icons {
  background: #1d2127;
}
html.dark .divider.divider-solid {
  background: #3F4247;
}
html.dark .divider.divider-style-2 .fa, html.dark .divider.divider-style-2 .icons {
  background: #21262d;
}
html.dark .divider.divider-style-3 .fa, html.dark .divider.divider-style-3 .icons {
  border-color: #3F4247;
}
html.dark .divider.divider-style-4 .fa, html.dark .divider.divider-style-4 .icons {
  border-color: #3F4247;
}
html.dark .divider.divider-style-4 .fa:after, html.dark .divider.divider-style-4 .icons:after {
  border-color: #21262d;
}
html.dark .divider.divider-small {
  background: transparent;
}
html.dark .divider.divider-small hr {
  background: #3F4247;
}
html.dark .divider.divider-small.divider-light hr {
  background: #3F4247;
}
html.dark hr.dashed:after, html.dark .divider.dashed:after {
  border-color: #3F4247;
}
html.dark .heading.heading-bottom-border h1 {
  border-bottom-color: #3f4247;
}
html.dark .heading.heading-bottom-border h2, html.dark .heading.heading-bottom-border h3 {
  border-bottom-color: #3f4247;
}
html.dark .heading.heading-bottom-border h4, html.dark .heading.heading-bottom-border h5, html.dark .heading.heading-bottom-border h6 {
  border-bottom-color: #3f4247;
}
html.dark .heading.heading-bottom-double-border h1, html.dark .heading.heading-bottom-double-border h2, html.dark .heading.heading-bottom-double-border h3 {
  border-bottom-color: #3f4247;
}
html.dark .heading.heading-bottom-double-border h4, html.dark .heading.heading-bottom-double-border h5, html.dark .heading.heading-bottom-double-border h6 {
  border-bottom-color: #3f4247;
}
html.dark .heading.heading-middle-border:before {
  border-top-color: #3f4247;
}
html.dark .heading.heading-middle-border h1, html.dark .heading.heading-middle-border h2, html.dark .heading.heading-middle-border h3, html.dark .heading.heading-middle-border h4, html.dark .heading.heading-middle-border h5, html.dark .heading.heading-middle-border h6 {
  background: #1d2127;
}
html.dark ul.history li .thumb {
  background-image: url(../img/no-image-available.png);
}
html.dark .recent-posts .date .day {
  background-color: #2e353e;
}
html.dark .blog-posts article {
  border-color: #282d36;
}
html.dark section.featured .recent-posts .date .day,
html.dark article.post .post-date .day {
  background-color: #282d36;
}
html.dark article .post-video,
html.dark article .post-video iframe,
html.dark article .post-audio,
html.dark article .post-audio iframe {
  background-color: #282d36;
  border-color: #282d36;
}
html.dark ul.simple-post-list li {
  border-bottom-color: #21262d;
}
html.dark .post-block {
  border-top-color: #191c21;
}
html.dark ul.comments .comment-block {
  background-color: #282d36;
}
html.dark ul.comments .comment-arrow {
  border-right-color: #282d36;
}
html.dark .pricing-table li {
  border-top-color: #21262d;
}
html.dark .pricing-table h3 {
  background-color: #21262d;
  text-shadow: none;
}
html.dark .pricing-table h3 span {
  background: #282d36;
  border-color: #242830;
  box-shadow: 0 5px 20px #242830 inset, 0 3px 0 #282d36 inset;
  color: #777;
}
html.dark .pricing-table .most-popular {
  border-color: #282d36;
}
html.dark .pricing-table .most-popular h3 {
  background-color: #282d36;
  color: #FFF;
  text-shadow: none;
}
html.dark .pricing-table .plan-ribbon {
  background-color: #282d36;
}
html.dark .pricing-table .plan {
  background: #282d36;
  border: 1px solid #282d36;
  color: #777;
  text-shadow: none;
}
html.dark .product-thumb-info {
  background-color: #282d36;
  border-color: transparent;
}
html.dark .shop .quantity .qty {
  background-color: #282d36;
  border-color: transparent;
}
html.dark .shop .quantity .minus,
html.dark .shop .quantity .plus {
  background-color: #21262d;
  border-color: transparent;
}
html.dark .shop table.cart td,
html.dark .shop .cart-totals th,
html.dark .shop .cart-totals td {
  border-color: #282d36;
}
html.dark .dialog {
  background-color: #1d2127;
}
html.dark .modal-content {
  background-color: #1d2127;
}
html.dark .modal-header {
  border-bottom-color: #282d36;
}
html.dark .modal-header h1, html.dark .modal-header h2, html.dark .modal-header h3, html.dark .modal-header h4, html.dark .modal-header h5, html.dark .modal-header h6 {
  color: #777;
}
html.dark .modal-header .close {
  text-shadow: none;
  color: #FFF;
}
html.dark .modal-footer {
  border-top-color: #282d36;
}
html.dark .popup-inline-content,
html.dark .mfp-content .ajax-container {
  background: #1d2127 !important;
}
html.dark .loading-overlay {
  background: #1d2127;
}
html.dark .sort-destination-loader:after {
  background-color: #1d2127;
}
html.dark #footer .newsletter form .btn-default {
  background-color: #21262d;
  border-color: #21262d;
  color: #777;
}

/* Responsive */
@media (min-width: 992px) {
  html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu {
    background: #21262d;
  }
  html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a {
    border-bottom-color: #2e353e;
  }
  html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-title {
    color: #999;
  }
  html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-nav > li:hover > a {
    background: #2e353e;
  }
  html.dark #header .header-nav-main:not(.header-nav-main-light) a > .thumb-info-preview .thumb-info-wrapper {
    background: #2e353e;
  }
  html.dark #header .header-nav.header-nav-stripe nav > ul > li > a {
    color: #CCC;
  }
  html.dark #header .header-nav.header-nav-stripe nav > ul > li:hover > a {
    color: #FFF;
  }
  html.dark #header .header-nav.header-nav-top-line nav > ul > li > a, html.dark #header .header-nav.header-nav-top-line nav > ul > li:hover > a {
    color: #CCC;
  }
  html.dark #header .header-nav.header-nav-dark-dropdown nav > ul > li > a, html.dark #header .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
    color: #CCC;
  }
  html.dark .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
    background-color: #1d2127;
  }
}
@media (max-width: 991px) {
  html.dark #header .header-nav-main nav > ul li {
    border-bottom-color: #2e353e;
  }
  html.dark #header.header-transparent .header-nav-main, html.dark #header.header-semi-transparent .header-nav-main, html.dark #header.header-semi-transparent-light .header-nav-main {
    background-color: #1d2127;
  }
}
/* Boxed Layout */
html.boxed body {
  background-color: transparent;
  background-position: 0 0;
  background-repeat: repeat;
}
html.boxed .body {
  position: relative;
  background-color: #FFF;
  border-bottom: 5px solid #1d2127;
  border-radius: 5px;
  border-top: 5px solid #CCC;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  margin: 25px auto;
  max-width: 1200px;
  height: auto;
}
html.boxed #header .header-body {
  border-top-color: transparent;
}
html.boxed .main {
  overflow: hidden;
}

/* Responsive */
@media (min-width: 1200px) {
  html.boxed.sticky-header-active #header .header-body {
    width: auto;
  }

  html.boxed #header.header-transparent .header-body,
  html.boxed #header.header-semi-transparent .header-body,
  html.boxed #header.header-semi-transparent-light .header-body {
    width: auto;
  }
}
@media (min-width: 992px) {
  html.boxed #header .header-top.header-top-colored {
    margin-top: -18px;
    border-radius: 4px 4px 0 0;
  }
  html.boxed #header.header-transparent .header-body,
  html.boxed #header.header-semi-transparent .header-body,
  html.boxed #header.header-semi-transparent-light .header-body {
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
  }
  html.boxed #header .header-top.header-top-style-2,
  html.boxed #header .header-top.header-top-style-3,
  html.boxed #header .header-top.header-top-style-4 {
    margin-top: -13px;
  }

  html.boxed.sticky-header-active #header .header-body {
    position: fixed !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  html.boxed.sticky-header-active #header .header-nav-bar {
    margin: 0 -15px -9px;
  }
}
@media (max-width: 991px) {
  html.boxed .body {
    margin: 0 auto;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  /* Boxed */
  html.boxed {
    background: none !important;
  }
}

/*# sourceMappingURL=theme.css.map */

body {
  font-size: 13px;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #313131;
  line-height: 1.35;
  margin: 0 0 15px;
  text-transform: none;
  letter-spacing: 0 !important;
}

h1,
.h1 {
  font-size: 22px;
  margin-bottom: 20px;
}

h2,
.h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 16px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

p {
  line-height: 1.5;
}

a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

hr.medium {
  margin: 33px 0;
}

.heading-text-color {
  color: #444 !important;
}

.text-primary {
  color: #9e1c2f !important;
}

.text-color {
  color: #444 !important;
}

h2.word-rotator-title .word-rotate {
  line-height: 35px;
  max-height: 35px;
  margin-bottom: -10px;
}

.btn-link {
  background-color: transparent;
  border: none;
}

@media (min-width: 992px) {
  .col-md-9 {
    padding-right: 12px;
  }
  .col-md-9.col-md-push-3 {
    padding-right: 15px;
    padding-left: 12px;
  }
  .col-md-9 + .col-md-3 {
    padding-left: 12px;
  }
  .col-md-9 + .col-md-3.col-md-pull-9 {
    padding-left: 15px;
    padding-right: 12px;
  }
}
@font-face {
  font-family: "minicart-font";
  src: url("../fonts/minicart-font.eot?v=1.0");
  src: url("../fonts/minicart-font.eot?#iefix&v=1.0") format("embedded-opentype"), url("../fonts/minicart-font.woff?v=1.0") format("woff"), url("../fonts/minicart-font.ttf?v=1.0") format("truetype"), url("../fonts/minicart-font.svg?v=1.0#minicart-font") format("svg");
  font-weight: normal;
  font-style: normal;
}
.minicart-icon:before {
  font-family: "minicart-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.minicart-icon:before {
  content: "\e800";
}

@media (max-width: 991px) {
  #header {
    min-height: auto !important;
  }
}

#tenant-name {
  font-size: xxx-large;
  color: #9e1c2f;
}

#user-name {
  color: #9e1c2f;
  padding-right: 5px;
}

#header .header-top {
  margin-top: 0;
  padding: 5.5px 0;
  background-color: #f7f7f7;
  border-bottom: none;
  color: #444;
}
#header .header-top p {
  color: #444;
  margin: 3px 20px 3px 0;
  text-align: left;
  float: right;
  font-size: 11px;
  line-height: 19px;
  text-transform: uppercase;
}
#header .top-menu {
  display: inline-block;
  padding: 0;
  margin: 3px 0;
  list-style: none;
}
#header .top-menu li {
  display: inline;
  font-size: 11px;
  padding: 0 10px;
  text-transform: uppercase;
  line-height: 19px;
  border-left: 1px solid #ccc;
}
@media (min-width: 992px) {
  #header .top-menu li {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#header .top-menu li a {
  display: inline-block;
  color: #9e1c2f;
}
#header .top-menu li:last-child {
  padding-right: 0;
}
@media (max-width: 767px) {
  #header .top-menu {
    display: none;
    position: absolute;
    min-width: 94px;
    right: 0;
    text-align: right;
    top: 100%;
    margin: 0;
    padding: 4px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
  #header .top-menu li {
    display: block;
    padding: 2px 9px;
    line-height: 1;
    border-left: none;
  }
  #header .top-menu li:last-child {
    padding-right: 9px;
  }
  #header .top-menu li a {
    font-size: 11px;
    line-height: 1;
    color: #444;
    text-decoartion: none;
  }
  #header .top-menu li:hover {
    background-color: #ccc;
  }
  #header .top-menu li:hover a {
    color: #fff;
  }
}
#header .top-menu-area {
  position: relative;
  float: right;
  display: block;
}
#header .top-menu-area > a {
  display: inline-block;
  font-size: 11px;
  line-height: 24px;
  color: #9e1c2f;
  padding-left: 10px;
  padding-right: 0;
  text-transform: uppercase;
}

#header .top-menu-area li button {
  display: inline-block;
  font-size: 11px;
  line-height: 24px;
  color: #9e1c2f;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;
}
#header .top-menu-area > a:hover, #header .top-menu-area > a:focus {
  text-decoration: none;
}
#header .top-menu-area > a i {
  margin-left: 4px;
}
@media (min-width: 768px) {
  #header .top-menu-area > a {
    display: none;
  }
}
@media (max-width: 420px) {
  #header .top-menu-area > a {
    padding-left: 6px;
  }
  #header .top-menu-area > a i {
    margin-left: 3px;
  }
}
@media (max-width: 767px) {
  #header .top-menu-area:hover .top-menu, #header .top-menu-area:focus .top-menu {
    display: block;
  }
}
#header .welcome-msg {
  margin: 3px 20px 3px 0;
  text-align: left;
  float: right;
  font-size: 11px;
  line-height: 19px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  #header .welcome-msg {
    display: none;
  }
}
#header .dropdowns-container {
  float: left;
}
#header .dropdowns-container:after {
  content: '';
  display: table;
  clear: both;
}
#header .header-dropdown {
  position: relative;
  float: left;
}
#header .header-dropdown > a {
  display: inline-block;
  font-size: 11px;
  line-height: 24px;
  color: #444;
  padding-left: 10px;
  padding-right: 15px;
}
#header .header-dropdown > a i {
  margin-left: 4px;
}
@media (max-width: 420px) {
  #header .header-dropdown > a {
    padding-left: 5px;
    padding-right: 10px;
  }
  #header .header-dropdown > a i {
    margin-left: 3px;
  }
}
#header .header-dropdown:first-child > a {
  padding-left: 0;
}
#header .header-dropdown a {
  text-transform: uppercase;
}
#header .header-dropdown a img {
  display: inline-block;
  max-width: 16px;
  height: auto;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}
#header .header-dropdown a:hover, #header .header-dropdown a:focus {
  text-decoration: none;
}
#header .header-dropdown .header-dropdownmenu {
  list-style: none;
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 4px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
#header .header-dropdown .header-dropdownmenu li {
  padding: 2px 9px;
  line-height: 1;
}
#header .header-dropdown .header-dropdownmenu li a {
  font-size: 11px;
  line-height: 1;
  color: #444;
}
#header .header-dropdown .header-dropdownmenu li a img {
  margin-top: -3px;
}
#header .header-dropdown .header-dropdownmenu li:hover {
  background-color: #ccc;
}
#header .header-dropdown .header-dropdownmenu li:hover a {
  color: #fff;
}
#header .header-dropdown.lang-dropdown .header-dropdownmenu {
  min-width: 98px;
}
#header .header-dropdown:hover .header-dropdownmenu, #header .header-dropdown:focus .header-dropdownmenu {
  display: block;
}
#header .header-dropdown + .header-dropdown,
#header .header-dropdown + .compare-dropdown {
  margin-left: 7px;
}
#header .header-dropdown + .header-dropdown:before,
#header .header-dropdown + .compare-dropdown:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 14px;
  position: absolute;
  left: -7px;
  top: 50%;
  margin-top: -7px;
  background-color: #ccc;
}
@media (max-width: 420px) {
  #header .header-dropdown + .header-dropdown,
  #header .header-dropdown + .compare-dropdown {
    margin-left: 4px;
  }
  #header .header-dropdown + .header-dropdown:before,
  #header .header-dropdown + .compare-dropdown:before {
    left: -5px;
  }
}
#header .compare-dropdown {
  position: relative;
  float: left;
}
@media (max-width: 350px) {
  #header .compare-dropdown {
    display: none;
  }
}
#header .compare-dropdown > a {
  display: inline-block;
  font-size: 11px;
  line-height: 24px;
  color: #9e1c2f;
  padding-left: 7px;
  padding-right: 15px;
  text-transform: uppercase;
}
@media (max-width: 420px) {
  #header .compare-dropdown > a {
    padding-left: 5px;
    padding-right: 10px;
  }
}
#header .compare-dropdown > a i {
  margin-top: -2px;
}
#header .compare-dropdown > a:hover, #header .compare-dropdown > a:focus {
  text-decoration: none;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper {
  padding: 20px;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .empty {
  margin: 0;
  float: none;
  font-size: 11px;
  line-height: 1.5;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-products {
  list-style: none;
  padding: 0;
  margin: 0;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-products .product {
  position: relative;
  padding: 5px 0;
  margin: 0;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-products .product-name {
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-actions {
  margin-top: 20px;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-actions:after {
  content: '';
  display: table;
  clear: both;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-actions .action-link {
  display: inline-block;
  float: left;
  line-height: 32px;
  color: #444;
}
#header .compare-dropdown .compare-dropdownmenu .dropdownmenu-wrapper .compare-actions .btn {
  float: right;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: 120px;
  border: none;
}
@media (min-width: 768px) {
  #header .compare-dropdown:hover .compare-dropdownmenu, #header .compare-dropdown:focus .compare-dropdownmenu {
    display: block;
  }
}
#header .header-body {
  border-top: none;
  border-bottom: none;
  padding: 0;
}
#header .header-logo img {
  margin: 0;
}
@media (max-width: 420px) {
  #header .header-logo img {
    max-width: 90px;
    height: auto;
  }
}
#header .header-container {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
  display: table;
  table-layout: fixed;
}
@media (max-width: 767px) {
  #header .header-container {
    width: 100%;
  }
}
#header .header-container .header-row {
  display: table-row;
}
#header .header-container .header-column {
  display: table-cell;
}
#header .header-container .header-column:first-child {
  text-align: left;
}
#header .header-container.header-nav {
  padding: 0;
  background-color: transparent;
}
#header .cart-area {
  float: right;
  vertical-align: middle;
}
@media (max-width: 991px) {
  #header .cart-area {
    margin-top: 5.5px;
  }
}
#header .cart-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 7px;
  padding-right: 7px;
}
#header .cart-dropdown .cart-dropdown-icon {
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 0;
  line-height: 40px;
  text-align: center;
  top: -1px;
  color: #fff;
  text-decoration: none !important;
}
#header .cart-dropdown .cart-dropdown-icon i {
  font-size: 35px;
  color: #9e1c2f;
}
#header .cart-dropdown .cart-dropdown-icon .cart-info {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -4px;
  left: 0;
  padding: 0;
  display: block;
  line-height: 1;
}
#header .cart-dropdown .cart-dropdown-icon .cart-info .cart-qty {
  font-size: 14px;
  font-weight: 600;
}
#header .cart-dropdown .cart-dropdown-icon .cart-info .cart-text {
  displaY: none;
  font-size: 12px;
  font-weight: 400;
}
#header .cart-dropdown .cart-dropdownmenu .cart-empty {
  padding: 20px 0;
  text-align: center;
}
#header .cart-dropdown .cart-dropdownmenu .cart-products {
  padding: 0 20px;
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm .product-image-area {
  padding: 0;
  border: none;
  border-radius: 0;
  width: 80px;
  margin: 0;
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm .product-image-area .product-image {
  border-radius: 0;
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm .product-details-area {
  float: none;
  margin: 0 0 0 90px;
  padding: 0;
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm .btn-remove {
  top: 28px;
}
@media (max-width: 350px) {
  #header .cart-dropdown .cart-dropdownmenu .product.product-sm .btn-remove {
    top: 50%;
    margin-top: -11.5px;
  }
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm .product-name {
  font-size: 13px;
  margin: 10px 0;
}
#header .cart-dropdown .cart-dropdownmenu .product.product-sm .cart-qty-price {
  color: #9e1c2f;
}
#header .cart-dropdown .cart-dropdownmenu .cart-totals {
  padding: 10px 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
#header .cart-dropdown .cart-dropdownmenu .cart-totals span {
  color: #9e1c2f;
}
#header .cart-dropdown .cart-dropdownmenu .cart-actions {
  font-size: 0;
  padding: 0 20px 15px;
  border-radius: 0 0 0 0;
}
#header .cart-dropdown .cart-dropdownmenu .cart-actions .btn {
  width: 128px;
  text-align: center;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media (max-width: 350px) {
  #header .cart-dropdown .cart-dropdownmenu .cart-actions .btn {
    display: block;
    width: 100%;
  }
}
#header .cart-dropdown .cart-dropdownmenu .cart-actions .btn + .btn {
  margin-left: 4px;
}
@media (max-width: 350px) {
  #header .cart-dropdown .cart-dropdownmenu .cart-actions .btn + .btn {
    margin-left: 0;
    margin-top: 4px;
  }
}
#header .cart-dropdown:hover .cart-dropdownmenu, #header .cart-dropdown:focus .cart-dropdownmenu {
  display: block;
}
#header .compare-dropdownmenu,
#header .cart-dropdownmenu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 300px;
  padding-top: 10px;
  z-index: 100;
  color: #444;
}
@media (max-width: 350px) {
  #header .compare-dropdownmenu,
  #header .cart-dropdownmenu {
    width: 240px;
  }
}
#header .compare-dropdownmenu .btn-remove,
#header .cart-dropdownmenu .btn-remove {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23px;
  height: 23px;
  overflow: hidden;
  padding: 5px 0;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  color: #444;
}
#header .compare-dropdownmenu .btn-remove:hover, #header .compare-dropdownmenu .btn-remove:focus,
#header .cart-dropdownmenu .btn-remove:hover,
#header .cart-dropdownmenu .btn-remove:focus {
  opacity: 0.9;
}
#header .compare-dropdownmenu .dropdownmenu-wrapper,
#header .cart-dropdownmenu .dropdownmenu-wrapper {
  border-top: 6px solid #9e1c2f;
  background-color: #fff;
  border-radius: 0 0 0 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
#header .compare-dropdownmenu .dropdownmenu-wrapper:before,
#header .cart-dropdownmenu .dropdownmenu-wrapper:before {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #9e1c2f;
  display: block;
  left: 15px;
  top: -10px;
}
#header .compare-dropdownmenu.right, #header .compare-dropdownmenu.pull-right,
#header .cart-dropdownmenu.right,
#header .cart-dropdownmenu.pull-right {
  float: none !important;
  left: auto;
  right: 0;
}
#header .compare-dropdownmenu.right .dropdownmenu-wrapper:before, #header .compare-dropdownmenu.pull-right .dropdownmenu-wrapper:before,
#header .cart-dropdownmenu.right .dropdownmenu-wrapper:before,
#header .cart-dropdownmenu.pull-right .dropdownmenu-wrapper:before {
  left: auto;
  right: 15px;
}
#header .custom-block {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  line-height: 39px;
  font-weight: 400;
  vertical-align: middle;
  color: #444;
  margin-right: 10px;
}
@media (max-width: 991px) {
  #header .custom-block {
    display: none;
  }
}
#header .custom-block i {
  margin-right: 5px;
}
#header .custom-block .split {
  display: inline-block;
  height: 14px;
  width: 1px;
  border-right: 1px solid #999;
  margin: 0 10px;
  vertical-align: middle;
}
#header .custom-block a {
  font-size: 12px;
}
#header .header-search {
  position: relative;
  float: left;
  margin: 0;
  font-size: 0;
  line-height: 1;
  padding: 0;
  border: none;
}
@media (max-width: 991px) {
  #header .header-search {
    margin-top: 5.5px;
    margin-left: 0;
    margin-right: 5px;
  }
}
@media (max-width: 360px) {
  #header .header-search {
    margin-left: 0;
    margin-right: 0;
  }
}
#header .header-search .search-toggle {
  display: inline-block;
  font-size: 14px;
  line-height: 40px;
  min-width: 25px;
  text-align: center;
  color: #9e1c2f;
}
@media (min-width: 992px) {
  #header .header-search .search-toggle {
    display: none;
  }
}
#header .header-search form {
  display: inline-block;
  width: 0;
}
@media (min-width: 992px) {
  #header .header-search form {
    width: 320px;
  }
}
@media (min-width: 992px) {
  #header .header-search form {
    width: 366px;
  }
}
#header .header-search .header-search-wrapper {
  display: none;
  overflow: visible;
  border: 1px solid #ccc;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  width: 100%;
  min-width: 250px;
  padding-right: 166px;
}
#header .header-search .header-search-wrapper.open {
  display: block;
}
#header .header-search .header-search-wrapper:after {
  content: '';
  display: table;
  clear: both;
}
@media (min-width: 992px) {
  #header .header-search .header-search-wrapper {
    display: block;
  }
}
@media (max-width: 991px) {
  #header .header-search .header-search-wrapper {
    position: absolute;
    left: -15px;
    top: 100%;
    z-index: 20;
    border-width: 5px;
    border-color: #ccc;
    width: 366px;
    border-radius: 0;
  }
  #header .header-search .header-search-wrapper:before {
    content: "";
    display: block;
    position: absolute;
    left: 13px;
    top: -23px;
    z-index: 20;
    width: 20px;
    height: 20px;
    border: 10px solid transparent;
    border-bottom-color: #ccc;
  }
}
@media (max-width: 480px) {
  #header .header-search .header-search-wrapper {
    width: 300px;
  }
}
@media (max-width: 350px) {
  #header .header-search .header-search-wrapper {
    width: 240px;
  }
}
#header .header-search .header-search-wrapper .form-control,
#header .header-search .header-search-wrapper select {
  float: left;
  height: 34px;
  font-family: Arial;
  font-size: 13px;
  background-color: #fff;
  margin: 0;
}
#header .header-search .header-search-wrapper .form-control {
  padding: 7px 15px;
  color: #444;
  width: 100%;
  margin: 0;
  line-height: 20px;
  border-radius: 0 0 0 0;
  box-shadow: none;
  border: none;
}
#header .header-search .header-search-wrapper select {
  position: absolute;
  right: 36px;
  width: 130px;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom: 0;
  line-height: 36px;
  color: #444;
  padding: 0;
  padding-left: 10px;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
@media (max-width: 350px) {
  #header .header-search .header-search-wrapper select {
    width: 110px;
  }
}
#header .header-search .header-search-wrapper .btn.btn-default {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  width: 36px;
  height: 34px;
  color: #444;
  background-color: transparent;
  font-size: 14px;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  border-radius: 0 0 0 0;
}
#header .header-search .header-search-wrapper .btn.btn-default:hover, #header .header-search .header-search-wrapper .btn.btn-default:focus {
  color: #9e1c2f;
  background-color: transparent;
}
#header .header-nav-main nav {
  background-color: transparent;
}
#header .header-nav-main nav > ul > li > a.dropdown-toggle {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  color: #828385;
  padding: 13px 15px 13px 15px;
}
#header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
  font-size: inherit;
  content: "\f107";
  right: 13px;
  top: 13px;
}
#header .header-nav-main nav > ul > li > a {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  color: #828385;
  padding: 13px 20px;
}
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a,
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a.dropdown-toggle {
  color: #fff;
}
#header .header-nav-main nav > ul > li:first-child > a,
#header .header-nav-main nav > ul > li:first-child > a.dropdown-toggle {
  padding-left: 0;
}
#header .header-nav-main nav > ul > li:hover > a,
#header .header-nav-main nav > ul > li:hover > a.dropdown-toggle, #header .header-nav-main nav > ul > li.open
#header .header-nav-main nav > ul > li.dropdown:hover > a,
#header .header-nav-main nav > ul > li.open
#header .header-nav-main nav > ul > li.dropdown:hover > a.dropdown-toggle, #header .header-nav-main nav > ul > li.dropdown.open > a,
#header .header-nav-main nav > ul > li.dropdown.open > a.dropdown-toggle {
  padding-bottom: 13px;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
  border-left: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 0;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li {
  padding: 5px;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:hover {
  background-color: #f4f4f4;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
  border-bottom: none;
  transition: none;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: inherit;
  border: none;
  margin: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu {
  border-radius: 0 0 0 0;
  padding: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content {
  padding: 0;
  padding: 10px 20px 20px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  left: 100%;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li {
  line-height: 22px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li a,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li a, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li a,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li a {
  padding: 0;
  margin: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li a:hover, #header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li a:focus,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li a:hover,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li a:focus, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li a:hover, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li a:focus,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li a:hover,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li a:focus {
  text-decoration: underline;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li:hover a,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li:hover a, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li:hover a,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li:hover a {
  background-color: transparent;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-sub-title, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-sub-title {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  text-transform: uppercase;
  line-height: 1.5;
  margin-top: 10px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .cat-img, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .cat-img {
  display: block;
  padding: 0;
  margin-top: 15px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .cat-img img, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .cat-img img {
  display: block;
  max-width: 100%;
  height: auto;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top {
  padding: 4px 0 8px;
  border-bottom: 1px solid #eee;
  color: #000;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top a,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top a,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  padding: 0;
  margin-right: 15px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span {
  font-weight: 700;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area {
  position: relative;
  text-align: center;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area img, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin: 20px auto 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header {
  position: absolute;
  top: -35px;
  left: -15px;
  text-align: left;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header h3, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header h3 {
  font-size: 23px;
  font-weight: 600;
  color: #fff;
  background-color: #2e2e2e;
  line-height: 1;
  padding: 6px 50px 6px 8px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn {
  font-size: 13px;
  padding: 5px 7px 5px 8px;
  color: #fff;
  border: 0;
  font-size: 13px;
  min-width: 109px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:hover, #header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:focus, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:hover, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:focus {
  opacity: 0.9;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn i, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn i {
  margin-left: 4px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area p, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area p {
  position: absolute;
  bottom: 8px;
  width: 60%;
  text-align: center;
  left: 50px;
  line-height: 14px;
  font-size: 13px;
  margin-bottom: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu {
  width: 600px;
}
#header .header-nav-main nav > ul > li.dropdown-mega-small .mega-banner-bg img {
  position: absolute;
  right: 10px;
  top: -10px;
  height: 273px;
  width: auto;
  max-width: none;
  z-index: -1;
  border-radius: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li {
  line-height: 23px;
}
#header .header-nav-main nav > ul > li:hover > a, #header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li.active > a, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a {
  color: #9e1c2f !important;
  background-color: transparent !important;
}
#header .header-nav-main nav > ul > li:hover > a:hover, #header .header-nav-main nav > ul > li:hover > a:focus, #header .header-nav-main nav > ul > li.open > a:hover, #header .header-nav-main nav > ul > li.open > a:focus, #header .header-nav-main nav > ul > li.active > a:hover, #header .header-nav-main nav > ul > li.active > a:focus, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:hover, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:focus, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:hover, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:focus, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a:hover, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a:focus {
  color: #9e1c2f !important;
  background-color: transparent !important;
}
@media (min-width: 992px) {
  #header .header-nav-main {
    margin-top: 0;
    float: none;
    min-height: 41px;
  }
}
#header .header-nav .cart-dropdown,
#header .header-nav .header-logo {
  display: none;
}
#header .header-nav .header-logo img {
  width: auto;
  height: 32px;
  margin-right: 20px;
  margin-top: 3px;
}
#header .header-nav .cart-dropdown {
  float: right;
}
#header .header-nav .cart-dropdown .cart-dropdown-icon {
  height: 34px;
  line-height: 34px;
  margin-top: 4px;
}
#header .header-nav .cart-dropdown .cart-dropdown-icon i {
  font-size: 31px;
}
#header .header-nav .cart-dropdown .cart-dropdown-icon .cart-info .cart-qty {
  font-size: 12px;
}
@media (max-width: 991px) {
  #header .header-nav,
  #header .header-nav-main {
    display: none;
  }
}

.sticky-header-active #header .header-nav {
  padding: 4px 0;
}
.sticky-header-active #header .header-nav-main {
  min-height: 38px;
  border-bottom: none;
}
.sticky-header-active #header .header-nav-main nav {
  background-color: transparent;
  height: 38px;
  padding-top: 3px;
}
.sticky-header-active #header .header-nav-main nav > ul > li.pull-right {
  float: left !important;
}
.sticky-header-active #header .header-nav-main nav > ul > li > a,
.sticky-header-active #header .header-nav-main nav > ul > li > a.dropdown-toggle {
  padding: 6px 15px;
}
.sticky-header-active #header .header-nav-main nav > ul > li > a.dropdown-toggle {
  padding-right: 25px;
}
.sticky-header-active #header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
  right: 12px;
  top: 6px;
}
.sticky-header-active #header .header-nav-main nav > ul > li.dropdown.open > a.dropdown-toggle,
.sticky-header-active #header .header-nav-main nav > ul > li.dropdown:hover > a.dropdown-toggle {
  padding-bottom: 9px;
}
.sticky-header-active #header .header-nav .cart-dropdown,
.sticky-header-active #header .header-nav .header-logo {
  display: block;
}

.tip {
  color: #fff;
  position: relative;
  display: inline-block;
  font-size: 9px;
  font-weight: 400;
  padding: 2px;
  z-index: 1;
  border-radius: 0;
  line-height: 1;
  margin: 0 0 0 10px;
  vertical-align: middle;
  text-transform: uppercase;
}
#mainNav .dropdown-menu li .tip {
  margin-top: -2px;
}
.tip:before {
  content: "";
  position: absolute;
  left: auto;
  right: 100%;
  top: 50%;
  margin-top: -4px;
  border: 3px solid transparent;
}
.tip.tip-new {
  background-color: #0cc485 !important;
}
.tip.tip-new:before {
  border-right-color: #0cc485 !important;
}
.tip.tip-hot {
  background-color: #eb2771 !important;
}
.tip.tip-hot:before {
  border-right-color: #eb2771 !important;
}
#mainNav > li > a .tip {
  position: absolute;
  top: -7px;
  right: 10px;
}
#mainNav > li > a .tip:before {
  position: absolute;
  left: 3px;
  top: 100%;
  width: 3px;
  height: 3px;
  margin: 0;
  border-color: transparent !important;
}
#mainNav > li > a .tip.tip-new:before {
  border-top-color: #0cc485 !important;
}
#mainNav > li > a .tip.tip-hot:before {
  border-top-color: #eb2771 !important;
}

.body {
  position: relative;
  left: 0;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  transition: left 0.3s;
}

.menu-toggle-btn {
  display: block;
  width: 30px;
  height: auto;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  float: left;
  margin: 5.5px 5px 5.5px 0;
  vertical-align: middle;
  color: #9e1c2f;
}
@media (min-width: 992px) {
  .menu-toggle-btn {
    display: none;
  }
}
.menu-toggle-btn:hover, .menu-toggle-btn:focus {
  color: #9e1c2f;
  text-decoration: none;
}

.mobile-nav {
  display: block !important;
  position: fixed;
  top: 0;
  left: -250px;
  z-index: 999;
  width: 250px;
  height: 100%;
  overflow-y: scroll;
  padding: 20px 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #151515;
}

.mobile-side-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mobile-side-menu > li > a {
  text-transform: uppercase;
}
.mobile-side-menu li {
  display: block;
  position: relative;
}
.mobile-side-menu li:after {
  content: '';
  display: table;
  clear: both;
}
.mobile-side-menu li a {
  display: block;
  font-size: 14px;
  line-height: 40px;
  background-color: transparent;
  color: #fff;
  border: 0;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none !important;
}
.mobile-side-menu li a:after {
  content: '';
  display: table;
  clear: both;
}
.mobile-side-menu li ul {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}
.mobile-side-menu li li a {
  margin-left: 10px;
  font-size: 12px;
}
.mobile-side-menu li li a:before {
  font: normal normal 16px/40px "FontAwesome";
  text-decoration: inherit;
  content: "\f105";
  -webkit-font-smoothing: antialiased;
  float: left;
  margin-right: 10px;
}
.mobile-side-menu li li li a {
  margin-left: 20px;
}
.mobile-side-menu li .menu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  font-size: 0;
  width: 40px;
  height: 40px;
  margin-top: 1px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.mobile-side-menu li .menu-toggle:after {
  content: "\f196";
  font-family: 'FontAwesome';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  width: 19px;
  height: 19px;
  font-size: 19px;
  line-height: 19px;
  display: block;
  position: absolute;
  left: 10px;
  top: 11px;
}
.mobile-side-menu li.open > .menu-toggle:after {
  content: "\f147";
}

#mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.menu-open #mobile-menu-overlay {
  opacity: .15;
  visibility: visible;
}

.menu-open.body {
  left: 250px;
}
.menu-open #mobile-menu-overlay {
  opacity: 0.15;
  visibility: visible;
}
.menu-open .mobile-nav {
  left: 0;
}

.social-icons li {
  box-shadow: none;
}
.social-icons li a {
  background: transparent;
}

.mfp-bg {
  background-color: rgba(255, 255, 255, 0.5);
}

.newsletter-popup {
  max-width: 700px;
  height: 324px;
  background: #f1f1f1;
  padding: 40px 40px 25px;
  border-top: 4px solid #9e1c2f;
  border-radius: 0;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.newsletter-popup-content {
  max-width: 300px;
  text-align: center;
}
.newsletter-popup-content .btn,
.newsletter-popup-content .form-control {
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.newsletter-popup-content .form-control {
  height: 34px;
}

.newsletter-popup h2 {
  font-weight: 700;
  color: #313131;
  font-size: 16px;
  line-height: 1;
  margin: 30px 0 12px;
}

.newsletter-popup p {
  font-size: 13px;
  line-height: 1.4;
  color: #444;
}

.newsletter-popup form {
  margin: 0 0 8px;
}

.newsletter-subscribe {
  font-size: 11px;
  text-align: left;
}
.newsletter-subscribe .checkbox {
  margin-top: 15px;
}
.newsletter-subscribe input {
  margin-top: 2px;
}
.newsletter-subscribe label {
  font-size: 11px;
}

.mfp-close-btn-in .newsletter-popup .mfp-close {
  color: #9e1c2f;
  font-weight: 700;
  opacity: 0.85;
  top: -5px;
}

.banners-container {
  margin-bottom: 25px;
}
.banners-container .row {
  margin-left: -10px;
  margin-right: -10px;
}
.banners-container .row [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.banners-container .banner h3 {
  font-size: 17px;
  text-align: center;
  color: #818385;
  line-height: 1.4;
  padding: 10px 0;
  font-weight: 600;
  margin: 0;
}
.banners-container .banner .banner-caption {
  background-color: #a1a2a3;
  color: #fff;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
}

.banner {
  display: block;
  position: relative;
  margin-bottom: 15px;
}
.banner > a {
  position: relative;
  display: block;
}
.banner > a:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 0;
}
.banner img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0;
}
.banner:hover > a:before {
  visibility: visible;
  opacity: 0.1;
}

.client {
  display: block;
  margin-bottom: 15px;
}
.client img {
  width: auto !important;
  max-width: 100% !important;
}

.slider-title {
  position: relative;
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 700;
  color: #313131;
  text-transform: uppercase;
}
.slider-title.v2 {
  font-size: 17px;
  text-transform: none;
  color: #828385;
  font-weight: 600;
  margin-bottom: 20px;
}
.slider-title .inline-title {
  background-color: #fff;
  padding-right: 20px;
  position: relative;
  z-index: 2;
}
.slider-title .line {
  display: block;
  height: 1px;
  position: relative;
  width: calc( 100% - 50px);
  left: 0;
  bottom: 0.55em;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 70%, transparent 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 70%, transparent 100%);
  z-index: 1;
}
.slider-title .view-all {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #9e1c2f;
  font-size: 13px;
  line-height: inherit;
  font-weight: 400;
  text-transform: capitalize;
}
.slider-title:after {
  content: '';
  display: table;
  clear: both;
}
.slider-title.text-center > .inline-title {
  padding: 0 20px;
}
.slider-title.text-center .line {
  background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
}

@media (max-width: 320px) {
  .slider-title .line {
    display: none;
  }
}
.customer-support-section {
  margin-bottom: 50px;
}
.customer-support-section .feature-box h4 {
  color: #000;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
}
.customer-support-section .feature-box h5 {
  color: #888;
  font-weight: 300;
  font-size: 11px;
  line-height: 1;
  margin-bottom: 15px;
}
.customer-support-section .feature-box .feature-box-info {
  padding-left: 76px;
  font-size: 14px;
  line-height: 1.5;
}
.customer-support-section .feature-box p {
  margin: 0 0 15px;
}
.customer-support-section .feature-box.feature-box-style-3 {
  min-height: 50px;
  margin-bottom: 20px;
}
.customer-support-section .feature-box.feature-box-style-3 .feature-box-icon {
  width: 62px;
  height: 62px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #cecece;
  color: #333;
  background-color: transparent;
  line-height: 58px;
  font-size: 25px;
}
.customer-support-section .feature-box.feature-box-style-3 .feature-box-icon i.fa {
  color: #000;
}

.explore-section {
  font-weight: 300;
}
.explore-section.section {
  margin-bottom: 70px;
}
@media (min-width: 768px) {
  .explore-section.section {
    margin-bottom: 80px;
  }
}
.explore-section .owl-carousel {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .explore-section .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 25px;
  }
}
.explore-section h2 {
  font-size: 60px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 15px;
}
.explore-section h2 strong {
  font-weight: 800;
}
.explore-section p {
  font-weight: 300;
  font-size: 23px;
  line-height: 1;
  margin-bottom: 30px;
}
.explore-section a {
  font-size: 16px;
  color: #fff;
}
.explore-section a:hover, .explore-section a:focus {
  color: #fff;
}
@media (max-width: 767px) {
  .explore-section h2 {
    font-size: 40px;
  }
  .explore-section p {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .explore-section a {
    font-size: 13px;
  }
}

.recent-posts-carousel .row {
  margin-left: -10px;
  margin-right: -10px;
}
.recent-posts-carousel .row [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.recent-posts-carousel .post .post-image {
  margin-bottom: 15px;
}
.recent-posts-carousel .post .post-image .img-thumbnail {
  padding: 2px;
  display: block;
}
.recent-posts-carousel .post h2 {
  font-weight: 400;
  min-height: 55px;
}
.recent-posts-carousel .post .post-content {
  font-size: 14px;
}
.recent-posts-carousel .post .post-content p {
  margin-bottom: 10px;
}
.recent-posts-carousel .post .btn.btn-link {
  padding: 0;
  font-size: 16px;
  font-weight: 300;
}

.owl-carousel .owl-nav {
  top: -40px;
  width: auto;
  right: 5px;
  margin: 0;
  line-height: 1;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  position: static;
  left: auto;
  right: auto;
  width: auto;
  height: auto;
  background-color: transparent !important;
  font-size: 18px;
  line-height: 1;
  min-width: 22px;
  color: #9e1c2f !important;
  padding: 0;
  margin: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-prev:focus,
.owl-carousel .owl-nav .owl-next:hover,
.owl-carousel .owl-nav .owl-next:focus {
  background-color: transparent !important;
  color: #b82137 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
}

.tparrows.custom {
  font-weight: 700;
  background: transparent;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
}
.tparrows.custom:hover {
  background: transparent;
}
.tparrows.custom:before {
  color: #9e1c2f;
  font-size: 40px;
}

.page-header {
  border: none;
  padding: 6.5px 0;
  min-height: 0;
  margin-bottom: 20px;
  background-color: #ffffff;
}
.page-header .breadcrumb {
  margin: 0;
}
.page-header .breadcrumb > li {
  font-size: 13px;
  text-transform: capitalize;
}
.page-header .breadcrumb > li > a {
  color: #444;
}
.page-header .breadcrumb > li > a:hover, .page-header .breadcrumb > li > a:ocus {
  color: #444;
}
.page-header .breadcrumb > li.active {
  color: #a09e9e;
}
.page-header .breadcrumb > li + li:before {
  content: '\f054';
  font-size: 12px;
  opacity: 1;
  color: #444;
}

.about-container {
  font-size: 14px;
}
.about-container p {
  line-height: 1.7;
}

.fullwidth-banner {
  position: relative;
  min-height: 150px;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 600px) {
  .fullwidth-banner {
    min-height: 200px;
  }
}
@media (min-width: 768px) {
  .fullwidth-banner {
    min-height: 350px;
  }
}
.fullwidth-banner > div {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fullwidth-banner > div h2 {
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
}
@media (min-width: 600px) {
  .fullwidth-banner > div h2 {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .fullwidth-banner > div h2 {
    font-size: 45px;
  }
}
.fullwidth-banner > div h2 strong {
  font-weight: 800;
}
.fullwidth-banner > div p {
  font-weight: 300;
  font-size: 16px;
  display: none;
  margin: 0;
  color: #fff;
}
@media (min-width: 768px) {
  .fullwidth-banner > div p {
    display: block;
  }
}
.fullwidth-banner:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #393733;
  opacity: .65;
  filter: alpha(opacity=65);
}

.boxed-banner-carosel {
  margin-bottom: 25px;
}
.boxed-banner-carosel .boxed-banner {
  margin-bottom: 0;
}
.boxed-banner-carosel .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
}

.boxed-banner {
  position: relative;
  margin-bottom: 25px;
}
.boxed-banner > img {
  display: block;
  height: auto;
  width: 100%;
  border-radius: 0;
}
.boxed-banner .banner-content {
  position: absolute;
  left: 5%;
  top: 37%;
}
.boxed-banner .banner-content h2 {
  color: #2b2b2b;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
}
@media (min-width: 600px) {
  .boxed-banner .banner-content h2 {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .boxed-banner .banner-content h2 {
    font-size: 28px;
    margin-bottom: 6px;
  }
}
@media (min-width: 992px) {
  .boxed-banner .banner-content h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
}
.boxed-banner .banner-content p {
  font-weight: 400;
  display: none;
  margin: 0;
  color: #2b2b2b;
  max-width: 280px;
}
@media (min-width: 768px) {
  .boxed-banner .banner-content p {
    display: block;
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .boxed-banner .banner-content p {
    font-size: 16px;
  }
}
.boxed-banner .banner-content img {
  display: inline-block;
  max-width: 50%;
  width: auto;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .boxed-banner .banner-content img {
    max-width: 100%;
  }
}
.boxed-banner .banner-content .shop-now {
  margin-left: 10px;
  font-size: 10px;
  color: #2b2b2b;
  vertical-align: middle;
}
@media (min-width: 480px) {
  .boxed-banner .banner-content .shop-now {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .boxed-banner .banner-content .shop-now {
    margin-left: 15px;
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .boxed-banner .banner-content .shop-now {
    margin-left: 20px;
    font-size: 16px;
  }
}

.toolbar-bottom {
  text-align: center;
  margin-top: 20px;
}
.toolbar-bottom .toolbar {
  display: inline-block;
}

.toolbar {
  margin-bottom: 8px;
}
.toolbar .sorter:after {
  content: '';
  display: table;
  clear: both;
}
.toolbar .sorter .sort-by {
  float: left;
  margin-right: 15px;
  margin-bottom: 4px;
}
.toolbar .sorter .sort-by a img {
  margin-top: -4px;
}
.toolbar .sorter .limiter {
  float: right;
}
.toolbar .sorter label {
  font-weight: 400;
  margin-right: 5px;
  color: #444;
  font-size: 13px;
  vertical-align: middle;
}
.toolbar .sorter select {
  background: #fff;
  border: 1px solid #ccc;
  padding: 2px 8px;
  height: 26px;
  color: #444;
  font-size: 13px;
  border-radius: 0;
}
.toolbar .sorter .view-mode {
  float: left;
  margin-right: 10px;
  line-height: 29px;
  height: 30px;
}
.toolbar .sorter .view-mode i {
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #ccc;
  width: 26px;
  height: 26px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  border-radius: 0;
  vertical-align: top;
}
.toolbar .sorter .view-mode span i, .toolbar .sorter .view-mode span:hover i, .toolbar .sorter .view-mode span:focus i {
  color: #fff;
  background-color: #9e1c2f;
  border-color: #9e1c2f;
}
.toolbar .sorter .view-mode a:hover i, .toolbar .sorter .view-mode a:focus i {
  color: #fff;
  background-color: #9e1c2f;
  border-color: #9e1c2f;
}
.toolbar .sorter .pagination {
  float: right;
  margin: 0 0 5px 8px;
}
.toolbar .sorter .pagination a,
.toolbar .sorter .pagination span {
  border-radius: 0;
  min-width: 26px;
  padding: 3px 6px;
  margin-left: 5px;
  text-align: center;
}

.products-grid {
  list-style: none;
  padding: 0;
  margin: 0 -10px 0;
}
.products-grid:after {
  content: '';
  display: table;
  clear: both;
}
.products-grid li {
  width: 100%;
  padding: 10px 10px 0;
}
.products-grid.columns6 {
  margin: 0 -5px;
}
.products-grid.columns6 li {
  padding-left: 5px;
  padding-right: 5px;
}
.products-grid.columns7 {
  margin: 0 -4px;
}
.products-grid.columns7 li {
  padding-left: 4px;
  padding-right: 4px;
}
.products-grid.columns8 {
  margin: 0 -3px;
}
.products-grid.columns8 li {
  padding-left: 3px;
  padding-right: 3px;
}
@media (min-width: 480px) {
  .products-grid li {
    float: left;
    width: 50%;
  }
  .products-grid li:nth-child(2n+1) {
    clear: left;
  }
  .products-grid.columns5 li, .products-grid.columns6 li, .products-grid.columns7 li, .products-grid.columns8 li {
    width: 33.33%;
  }
  .products-grid.columns5 li:nth-child(2n+1), .products-grid.columns6 li:nth-child(2n+1), .products-grid.columns7 li:nth-child(2n+1), .products-grid.columns8 li:nth-child(2n+1) {
    clear: none;
  }
  .products-grid.columns5 li:nth-child(3n+1), .products-grid.columns6 li:nth-child(3n+1), .products-grid.columns7 li:nth-child(3n+1), .products-grid.columns8 li:nth-child(3n+1) {
    clear: left;
  }
}
@media (min-width: 768px) {
  .products-grid.columns3 li, .products-grid.columns4 li {
    width: 33.33%;
  }
  .products-grid.columns3 li:nth-child(2n+1), .products-grid.columns4 li:nth-child(2n+1) {
    clear: none;
  }
  .products-grid.columns3 li:nth-child(3n+1), .products-grid.columns4 li:nth-child(3n+1) {
    clear: left;
  }
  .products-grid.columns5 li, .products-grid.columns6 li, .products-grid.columns7 li, .products-grid.columns8 li {
    width: 25%;
  }
  .products-grid.columns5 li:nth-child(3n+1), .products-grid.columns6 li:nth-child(3n+1), .products-grid.columns7 li:nth-child(3n+1), .products-grid.columns8 li:nth-child(3n+1) {
    clear: none;
  }
  .products-grid.columns5 li:nth-child(4n+1), .products-grid.columns6 li:nth-child(4n+1), .products-grid.columns7 li:nth-child(4n+1), .products-grid.columns8 li:nth-child(4n+1) {
    clear: left;
  }
}
@media (min-width: 992px) {
  .products-grid.columns6 li, .products-grid.columns7 li, .products-grid.columns8 li {
    width: 20%;
  }
  .products-grid.columns6 li:nth-child(4n+1), .products-grid.columns7 li:nth-child(4n+1), .products-grid.columns8 li:nth-child(4n+1) {
    clear: none;
  }
  .products-grid.columns6 li:nth-child(5n+1), .products-grid.columns7 li:nth-child(5n+1), .products-grid.columns8 li:nth-child(5n+1) {
    clear: left;
  }
}
@media (min-width: 1200px) {
  .products-grid.columns4 li {
    width: 25%;
  }
  .products-grid.columns4 li:nth-child(3n+1) {
    clear: none;
  }
  .products-grid.columns4 li:nth-child(4n+1) {
    clear: left;
  }
  .products-grid.columns5 li {
    width: 20%;
  }
  .products-grid.columns5 li:nth-child(4n+1) {
    clear: none;
  }
  .products-grid.columns5 li:nth-child(5n+1) {
    clear: left;
  }
  .products-grid.columns6 li {
    width: 16.66%;
  }
  .products-grid.columns6 li:nth-child(5n+1) {
    clear: none;
  }
  .products-grid.columns6 li:nth-child(6n+1) {
    clear: left;
  }
  .products-grid.columns7 li {
    width: 14.25%;
  }
  .products-grid.columns7 li:nth-child(5n+1) {
    clear: none;
  }
  .products-grid.columns7 li:nth-child(7n+1) {
    clear: left;
  }
  .products-grid.columns8 li {
    width: 12.5%;
  }
  .products-grid.columns8 li:nth-child(5n+1) {
    clear: none;
  }
  .products-grid.columns8 li:nth-child(8n+1) {
    clear: left;
  }
}

.products-list {
  list-style: none;
  padding: 0;
  margin: 0 -10px 0;
}
.products-list li {
  padding: 10px;
  width: 100%;
}

.product {
  position: relative;
}
.product .product-image-area {
  overflow: hidden;
  position: relative;
  background-color: #fff;
  border-radius: 0;
}
.product .product-image-area .product-image {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 0;
}
.product .product-image-area .product-image img {
  display: block;
  width: 100%;
  height: 390px;
  object-fit: contain;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.product .product-image-area .product-image .product-hover-image {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.product .product-image-area .product-actions {
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0;
  text-align: left;
  padding: 0 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.product .product-image-area .product-actions .addtocart,
.product .product-image-area .product-actions .comparelink,
.product .product-image-area .product-actions .addtowishlist {
  opacity: 1;
  visibility: visible;
  left: auto;
  right: auto;
  width: 32px;
  height: 32px;
  line-height: 30px;
  font-size: 17px;
  background-color: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
.product .product-quickview {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #9e1c2f;
  color: #fff;
  padding: 10px;
  z-index: 9;
  border-radius: 0 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  font-size: 12px;
  line-height: 1.4;
}
.product .product-quickview span {
  margin-left: 2px;
}
.product .product-quickview:hover, .product .product-quickview:focus {
  opacity: 0.9 !important;
  color: #fff;
  text-decoration: none;
}
.product .product-quickview:hover span, .product .product-quickview:focus span {
  text-decoration: underline;
}
.product .product-label {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  line-height: 1;
  z-index: 5;
  text-align: center;
}
.product .product-label + .product-label {
  top: 40px;
}
.product .product-label span {
  display: block;
  position: relative;
  padding: 7px 10px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
}
.product .product-label span.discount {
  background-color: #e27c7c;
}
.product .product-label span.new {
  background-color: #62b959;
}
.product .product-details-area {
  padding: 10px;
  text-align: center;
}
.product .product-details-area .product-actions {
  margin: 0 -10px;
}
.product .product-name {
  color: #444;
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 3px;
}
.product .product-name a {
  color: #444;
}
.product .product-name a:hover, .product .product-name a:focus {
  color: #9e1c2f;
  text-decoration: none;
}
.product:hover .product-image-area .product-actions {
  visibility: visible;
  opacity: 1;
}
.product:hover .product-image-area .product-actions .addtocart {
  color: #9e1c2f;
  background-color: #fff;
  border: 1px solid #9e1c2f;
}
.product:hover .product-image-area .product-actions .addtocart:hover, .product:hover .product-image-area .product-actions .addtocart:focus {
  color: #fff;
  border-color: #9e1c2f;
  background-color: #9e1c2f;
}
.product:hover .product-image-area .product-image .product-hover-image {
  visibility: visible;
  opacity: 1;
}
.product:hover .product-quickview {
  visibility: visible;
  opacity: 1;
}

.product-ratings {
  font-size: 11px;
  line-height: 1.25;
  background: url(../img/no-image-available.png) center no-repeat;
  height: 14px;
  margin: 7px 0 6px;
}
.product-ratings .ratings-box {
  position: relative;
  display: inline-block;
  margin-top: 1.5px;
  overflow: hidden;
  width: 64px;
  height: 11px;
  font-size: 0;
  line-height: 0;
  text-indent: -999em;
}
.product-ratings .ratings-box:before {
  font-family: "FontAwesome";
  content: "\f005\20\f005\20\f005\20\f005\20\f005";
  width: 64px;
  height: 11px;
  color: #c3c5c9;
  display: block;
  font-size: 11px;
  line-height: 11px;
}
.product-ratings .ratings-box .rating {
  float: left;
  height: 11px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.product-ratings .ratings-box .rating:before {
  font-family: "FontAwesome";
  content: "\f005\20\f005\20\f005\20\f005\20\f005";
  width: 64px;
  height: 11px;
  color: #ffc600;
  display: block;
  font-size: 11px;
  line-height: 11px;
}

.product-price-box {
  font-size: 0;
  margin: 5px 0 3px;
}
.product-price-box .old-price,
.product-price-box .product-price {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
}
.product-price-box .old-price {
  color: #999;
  font-size: 14px;
  text-decoration: line-through;
}
.product-price-box .old-price + .product-price {
  margin-left: 6px;
}
.product-price-box .product-price {
  font-size: 20px;
  color: #444;
}

.product-actions a {
  display: inline-block;
  position: relative;
  margin: 10px 1px;
  vertical-align: middle;
  border-radius: 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.product-actions a:hover, .product-actions a:focus,
.product-actions button:hover, .product-actions button:focus{
  text-decoration: none;
}
.product-actions a.addtocart,
.product-actions button.addtocart{
  color: #333;
  background-color: #fff;
  font-size: 14px;
  padding: 0 10px 0 8px;
  height: 32px;
  line-height: 30px;
  border: 1px solid #ccc;
  vertical-align: middle;
}
.product-actions a.addtocart i,
.product-actions button.addtocart i{
  font-size: 15px;
  margin-right: 2px;
}
.product:hover .product-actions a.addtocart,
.product:hover .product-actions button.addtocart {
  background-color: #9e1c2f;
  border-color: #9e1c2f;
  color: #fff;
}
.product-actions a.addtocart.outofstock {
  padding: 0 10px;
  cursor: default;
}
.product:hover .product-actions a.addtocart.outofstock {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #ccc !important;
}
.product-actions a.addtowishlist, .product-actions a.comparelink, .product-actions a.quickview {
  font-size: 17px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
}
.hide-addtolinks .product-actions a.addtowishlist, .hide-addtolinks .product-actions a.comparelink, .hide-addtolinks .product-actions a.quickview {
  display: none;
}
.product-actions a.addtowishlist {
  right: -37px;
  color: #ed4949;
  border: 1px solid #ed4949;
}
.product-actions a.addtowishlist:hover {
  color: #fff;
  background-color: #ed4949;
}
.product:hover .product-actions a.addtowishlist {
  visibility: visible;
  opacity: 1;
  right: 0;
}
.product-actions a.comparelink {
  left: -37px;
  color: #52b9b5;
  border: 1px solid #52b9b5;
}
.product-actions a.comparelink:hover {
  color: #fff;
  background-color: #52b9b5;
}
.product:hover .product-actions a.comparelink {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.product-actions a.quickview {
  visibility: visible;
  opacity: 1;
  color: #9e1c2f;
  border: 1px solid #9e1c2f;
}
.product-actions a.quickview:hover {
  color: #fff;
  background-color: #9e1c2f;
}

.product.product-list:after {
  content: '';
  display: table;
  clear: both;
}
.product.product-list .product-details-area {
  text-align: left;
  padding: 0 20px;
}
.product.product-list .product-short-desc {
  font-size: 14px;
  line-height: 1.5;
}
.product.product-list .product-ratings {
  background: none;
  margin-bottom: 10px;
}
.product.product-list .product-name {
  font-size: 18px;
  margin: 10px 0;
}
.product.product-list .product-price-box {
  margin-top: 10px;
}
.product.product-list .product-actions {
  margin: 0;
}
.product.product-list .product-actions a.addtowishlist, .product.product-list .product-actions a.comparelink, .product.product-list .product-actions a.quickview {
  left: auto;
  right: auto;
  visibility: visible;
  opacity: 1;
}
.product.product-list .product-actions a.addtocart {
  background-color: #9e1c2f;
  border-color: #9e1c2f;
  color: #fff;
}
@media (min-width: 600px) {
  .product.product-list .product-image-area {
    float: left;
    width: 20%;
  }
  .product.product-list .product-details-area {
    float: left;
    width: 80%;
  }
}

.product.product-sm {
  padding: 6px 0 8px;
  max-width: 300px;
}
.product.product-sm:after {
  content: '';
  display: table;
  clear: both;
}
.product.product-sm .product-image-area {
  width: 33%;
  float: left;
  padding: 0;
}
.product.product-sm .product-details-area {
  float: left;
  width: 67%;
  text-align: left;
  padding: 10px 16px;
}
.product.product-sm .product-name {
  font-size: 12px;
}
.product.product-sm .product-ratings {
  background: none;
  margin-bottom: 5px;
}
.product.product-sm .product-price-box {
  margin: 2px 0 3px;
}
.product.product-sm .product-price-box .old-price {
  font-size: 11px;
}
.product.product-sm .product-price-box .old-price + .product-price {
  margin-left: 3px;
}
.product.product-sm .product-price-box .product-price {
  font-size: 15px;
}

.sidebar.shop-sidebar .panel-group {
  margin-bottom: 40px;
}
.sidebar.shop-sidebar .panel-group .panel + .panel {
  margin-top: 14px;
}
.sidebar.shop-sidebar .panel.panel-default {
  border-radius: 0;
  border: none;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading {
  border-radius: 0;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading .panel-title {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #444;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading a {
  position: relative;
  border-radius: 0 0 0 0;
  padding-top: 11.5px;
  padding-bottom: 11.5px;
  padding-right: 45px;
  color: #444;
  border: 1px solid #ddd;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading a:before {
  font-family: 'FontAwesome';
  content: "\f0d8";
  width: 26px;
  height: 26px;
  display: block;
  border: 1px solid #ddd;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -13px;
  border-radius: 0;
  color: #ccc;
  text-align: center;
  line-height: 24px;
  background-color: #fff;
  font-size: 10px;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading a:hover:before {
  background-color: #9e1c2f;
  border-color: #9e1c2f;
  color: #fff;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading a.collapsed {
  border-radius: 0;
}
.sidebar.shop-sidebar .panel.panel-default .panel-heading a.collapsed:before {
  content: "\f0d7";
}
.sidebar.shop-sidebar .panel.panel-default .panel-body {
  padding: 15px 15px 7px;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 0 0;
  background-color: #fbfbfb;
}
.sidebar.shop-sidebar .filter-price {
  margin: 14px 0 9px;
}
.sidebar.shop-sidebar .filter-price #price-slider {
  margin-bottom: 20px;
}
.sidebar.shop-sidebar .filter-price .noUi-target {
  background: #eee;
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.sidebar.shop-sidebar .filter-price .noUi-handle {
  background: #9e1c2f;
  cursor: pointer;
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.sidebar.shop-sidebar .filter-price .noUi-handle:before, .sidebar.shop-sidebar .filter-price .noUi-handle:after {
  display: none;
}
.sidebar.shop-sidebar .filter-price .noUi-horizontal {
  position: relative;
  height: 7px;
}
.sidebar.shop-sidebar .filter-price .noUi-horizontal .noUi-handle {
  position: absolute;
  width: 13px;
  height: 18px;
  border: 0;
  left: -6.5px;
  top: -6px;
}
.sidebar.shop-sidebar .filter-price .noUi-horizontal .noUi-base .noUi-origin {
  position: absolute;
}
.sidebar.shop-sidebar .filter-price .noUi-connect {
  background-color: #da2d46;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
}
.sidebar.shop-sidebar .filter-price .filter-price-details {
  text-align: center;
}
.sidebar.shop-sidebar .filter-price .filter-price-details * {
  white-space: normal;
}
.sidebar.shop-sidebar .filter-price .filter-price-details span {
  vertical-align: middle;
  line-height: 26px;
}
.sidebar.shop-sidebar .filter-price .filter-price-details .form-control {
  display: inline-block;
  vertical-align: middle;
  color: #a3a2a2;
  padding: 2px 5px;
  font-size: 14px;
  margin: 0 2px;
  width: 50px;
  height: 26px;
}
.sidebar.shop-sidebar .filter-price .filter-price-details .btn {
  border: none;
  height: 26px;
  line-height: 26px;
  color: #fff;
  border-radius: 0;
  padding: 0 10px;
  font-size: 14px;
  margin: 5px 0;
}
.sidebar.shop-sidebar ul, .sidebar.shop-sidebar ol {
  padding: 0;
  margin: -7px 0 0;
  list-style: none;
}
.sidebar.shop-sidebar ul li a, .sidebar.shop-sidebar ol li a {
  color: #444;
  line-height: 2.5;
}
.sidebar.shop-sidebar .configurable-filter-list {
  padding: 0;
  margin: 0 -5px;
  list-style: none;
  font-size: 0;
}
.sidebar.shop-sidebar .configurable-filter-list li {
  display: inline-block;
  margin: 0 5px 8px;
}
.sidebar.shop-sidebar .configurable-filter-list li a {
  display: block;
  color: #444;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 28px;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 0;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
}
.sidebar.shop-sidebar .configurable-filter-list li a:hover {
  border-color: #9e1c2f;
  text-decoration: none;
}
.sidebar.shop-sidebar .configurable-filter-list.filter-list-color li a {
  padding: 1px;
}
.sidebar.shop-sidebar .configurable-filter-list.filter-list-color li a span {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 0;
}
.sidebar.shop-sidebar h4 {
  margin-bottom: 10px;
  text-transform: uppercase;
}
.sidebar.shop-sidebar .owl-carousel .owl-nav {
  top: -28px;
  width: auto;
  right: 5px;
  margin: 0;
  line-height: 1;
}
.sidebar.shop-sidebar .owl-carousel .owl-nav .owl-prev,
.sidebar.shop-sidebar .owl-carousel .owl-nav .owl-next {
  font-size: 16px;
  line-height: 1;
  min-width: 18px;
}

.product-essential {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .product-img-box {
    margin-bottom: 25px;
  }
}
.product-img-box img {
  display: block;
  width: 100%;
  height: auto;
}
.product-img-box .product-img-wrapper {
  border-radius: 0;
}
.product-img-box .owl-carousel {
  margin-bottom: 0;
}

.zoomContainer {
  z-index: 49;
}

.product-img-box-wrapper {
  position: relative;
  margin-bottom: 10px;
}
.product-img-box-wrapper .product-img-zoom {
  position: absolute;
  right: 11px;
  bottom: 7px;
  z-index: 50;
}

.product-details-box .product-nav-container {
  float: right;
}
@media (max-width: 767px) {
  .product-details-box .product-nav-container {
    margin-top: -15px;
  }
}
.product-details-box .product-nav-container .product-nav {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 0;
}
.product-details-box .product-nav-container .product-nav a {
  color: #555;
  display: inline-block;
  font-size: 22px;
  line-height: 58px;
  text-decoration: none;
  min-width: 31px;
  text-align: center;
}
.product-details-box .product-nav-container .product-nav a:hover, .product-details-box .product-nav-container .product-nav a:focus {
  text-decoration: none;
}
.product-details-box .product-nav-container .product-nav .product-nav-dropdown {
  border-top: 3px solid #9e1c2f;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  margin-right: -17.5px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  text-align: center;
  border-radius: 0;
}
.product-details-box .product-nav-container .product-nav .product-nav-dropdown:before {
  content: "";
  width: 5px;
  height: 2px;
  border: 5px solid transparent;
  border-bottom-color: #9e1c2f;
  display: block;
  position: absolute;
  top: -13px;
  right: 32px;
}
.product-details-box .product-nav-container .product-nav .product-nav-dropdown img {
  display: block;
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}
.product-details-box .product-nav-container .product-nav .product-nav-dropdown h4 {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.35;
  color: #444;
}
.product-details-box .product-nav-container .product-nav.product-nav-prev .product-nav-dropdown {
  right: auto;
  left: 0;
  margin-left: -17.5px;
  margin-right: 0;
}
.product-details-box .product-nav-container .product-nav.product-nav-prev .product-nav-dropdown:before {
  right: auto;
  left: 32px;
}
.product-details-box .product-nav-container .product-nav:hover > .product-nav-dropdown {
  visibility: visible;
  opacity: 1;
}
.product-details-box .product-nav-container .product-nav:hover > a {
  color: #9e1c2f;
  text-decoration: none;
}
.product-details-box .product-name {
  margin: 15px 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  color: #555;
}
.product-details-box .product-rating-container {
  margin-bottom: 20px;
}
.product-details-box .product-rating-container .product-ratings {
  vertical-align: middle;
  display: inline-block;
  background: none;
  margin: 0 2px 0 0;
  font-size: 16px;
  height: 20px;
}
.product-details-box .product-rating-container .product-ratings .ratings-box {
  margin-top: 1.5px;
  width: 100px;
  height: 16px;
}
.product-details-box .product-rating-container .product-ratings .ratings-box:before {
  width: 100px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}
.product-details-box .product-rating-container .product-ratings .ratings-box .rating {
  height: 16px;
}
.product-details-box .product-rating-container .product-ratings .ratings-box .rating:before {
  width: 100px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}
.product-details-box .product-rating-container .review-link {
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  color: #bdbdbd;
}
.product-details-box .product-rating-container .review-link a {
  color: #bdbdbd;
  display: inline-block;
  vertical-align: bottom;
  padding: 0 5px;
}
.product-details-box .product-rating-container .review-link a:hover, .product-details-box .product-rating-container .review-link a:focus {
  text-decoration: none;
  color: #9e1c2f;
}
.product-details-box .product-short-desc {
  padding: 0 0 10px;
  border-bottom: 1px solid #ebebeb;
}
.product-details-box .product-short-desc p {
  font-size: 14px;
  line-height: 1.65;
  margin: 0 0 20px;
}
.product-details-box .product-detail-info {
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #ebebeb;
}
.product-details-box .product-detail-info .product-price-box {
  margin: 0 0 20px;
}
.product-details-box .product-detail-info .product-price-box .old-price {
  vertical-align: bottom;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  color: #999;
}
.product-details-box .product-detail-info .product-price-box .product-price {
  font-size: 33px;
  line-height: 1;
  color: #9e1c2f;
}
.product-details-box .product-detail-info .availability {
  margin: 0 0 10px;
  font-size: 14px;
  color: #444;
  font-weight: 400;
}
.product-details-box .product-detail-info .email-to-friend {
  margin-bottom: 0;
}
.product-details-box .product-detail-info .email-to-friend a {
  color: #9e1c2f;
}
.product-details-box .product-detail-options {
  margin: 20px 0 0;
  position: relative;
  padding-bottom: 0;
  border-bottom: 1px solid #ebebeb;
}
.product-details-box .product-detail-options label {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 10px;
}
.product-details-box .product-detail-options label span:last-child {
  margin-left: 5px;
  font-weight: 400;
}
.product-details-box .product-detail-options .configurable-filter-list {
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
  font-size: 0;
}
.product-details-box .product-detail-options .configurable-filter-list li {
  display: inline-block;
  margin: 0 3px 5px 0;
}
.product-details-box .product-detail-options .configurable-filter-list li a {
  display: block;
  color: #444;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 28px;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 0;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
}
.product-details-box .product-detail-options .configurable-filter-list li a:hover {
  border-color: #9e1c2f;
  text-decoration: none;
}
.product-details-box .product-detail-options .configurable-filter-list.filter-list-color li a {
  padding: 1px;
}
.product-details-box .product-detail-options .configurable-filter-list.filter-list-color li a span {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 0;
}
.product-details-box .product-detail-qty {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  width: 60px;
}
.product-details-box .product-detail-qty #product-vqty {
  border-radius: 0;
  width: 35px !important;
  border-color: #e1e1e1;
  color: #9e1c2f;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
}
.product-details-box .product-detail-qty .btn {
  border-radius: 0;
}
.product-details-box .product-detail-qty .btn.btn-default {
  color: #ccc;
  font-size: 8px;
  border-color: #e1e1e1;
}
.product-details-box .product-detail-qty .btn.btn-default.bootstrap-touchspin-up {
  margin-top: -2px;
}
.product-details-box .product-detail-qty .btn.btn-default.bootstrap-touchspin-down {
  margin-bottom: -2px;
}
.product-details-box .product-actions {
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.product-details-box .product-actions:after {
  content: '';
  display: table;
  clear: both;
}
.product-details-box .product-actions .addtocart {
  color: #fff;
  border-color: #9e1c2f;
  background-color: #9e1c2f;
  line-height: 36px;
  height: 38px;
  min-width: 160px;
  text-align: center;
}
.product-details-box .product-actions .addtocart:hover, .product-details-box .product-actions .addtocart:focus {
  color: #fff;
  border-color: #b82137;
  background-color: #b82137;
}
.product-details-box .product-actions .actions-right {
  float: right;
}
@media (max-width: 480px) {
  .product-details-box .product-actions .actions-right {
    float: none;
    margin-top: -5px;
  }
}
.product-details-box .product-actions .actions-right .addtowishlist,
.product-details-box .product-actions .actions-right .comparelink {
  opacity: 1;
  visibility: visible;
  left: auto;
  right: auto;
  width: 38px;
  height: 38px;
  line-height: 38px;
  font-size: 17px;
}
.product-details-box .product-actions .actions-right .addtowishlist + .comparelink {
  margin-left: 5px;
}
@media (min-width: 768px) {
  .product-details-box .product-share-box {
    padding-bottom: 20px;
  }
}

.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  /*
  width: 1%;
  */
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  padding: 1px 4px;
  margin-left: -1px;
  position: relative;
  width: 22px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 5px;
  left: 6px;
  font-size: 9px;
  font-weight: normal;
}

.sidebar .feature-box h4 {
  color: #313131;
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  padding-top: 6px;
  font-weight: 600;
}
.sidebar .feature-box .feature-box-info {
  padding-left: 60px;
  font-size: 12px;
}
.sidebar .feature-box.feature-box-style-3 {
  min-height: 50px;
  margin-bottom: 20px;
}
.sidebar .feature-box.feature-box-style-3 .feature-box-icon {
  font-size: 30px;
  color: #000;
  float: left;
  width: 50px;
  height: 50px;
  line-height: 48px;
  border: 1px solid #bbb;
  border-radius: 50%;
  text-align: center;
}
.sidebar .feature-box.feature-box-style-3 .feature-box-icon i.fa {
  color: #000;
}
.sidebar.product-sidebar .product.product-sm .product-image-area {
  width: 44%;
}
.sidebar.product-sidebar .product.product-sm .product-details-area {
  width: 56%;
}
.sidebar.product-sidebar .product.product-sm .product-name {
  font-size: 13px;
  margin-bottom: 10px;
}
.sidebar.product-sidebar .product.product-sm .product-price-box .product-price {
  font-size: 20px;
}
.sidebar.product-sidebar .owl-carousel .owl-nav {
  top: -68px;
}

.tabs.product-tabs {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .tabs.product-tabs {
    margin-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .tabs.product-tabs .nav-tabs li {
    display: block;
  }
}
.tabs.product-tabs .tab-content {
  padding: 37px 15px;
  min-height: 200px;
  border-radius: 0 0 0 0;
  box-shadow: 0 1px 2px #eee;
  background-color: #fff;
}
.tabs.product-tabs .tab-content p {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .tabs.product-tabs.tabs-left .nav-tabs {
    display: block;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 767px) {
  .tabs.product-tabs.tabs-left li {
    display: block;
  }
}
.tabs.product-tabs.tabs-left li a {
  border-radius: 0 0 0 0;
  border-top: 1px solid #eee !important;
  border-bottom: 1px solid #eee !important;
}
@media (min-width: 768px) {
  .tabs.product-tabs.tabs-left li a {
    border-right: none;
    margin-right: -1px;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .tabs.product-tabs.tabs-left li a {
    margin-left: 0;
    margin-right: 0;
    border-right: 1px solid #eee !important;
  }
}
@media (min-width: 768px) {
  .tabs.product-tabs.tabs-left li + li {
    margin-top: 2px;
  }
}
.tabs.product-tabs.tabs-left .tab-content {
  border-left: 1px solid #eee;
  padding: 37px 15px 27px;
}
@media (min-width: 768px) {
  .tabs.product-tabs.tabs-left .tab-content {
    min-height: 250px;
  }
}
@media (max-width: 767px) {
  .tabs.product-tabs.tabs-left .tab-content {
    display: block;
    width: 100%;
    height: auto;
    padding-top: 25px;
    padding-bottom: 15px;
  }
}

.product-table,
.ratings-table {
  width: 100%;
  border-spacing: 0;
  empty-cells: show;
  font-size: 100%;
}
.product-table thead th,
.product-table tbody td,
.ratings-table thead th,
.ratings-table tbody td {
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  padding: 15px 10px;
  line-height: 1.3;
}
@media (max-width: 600px) {
  .product-table thead th,
  .product-table tbody td,
  .ratings-table thead th,
  .ratings-table tbody td {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.product-table {
  border: 1px solid #ddd;
}
.product-table .table-label {
  font-weight: 700;
  color: #000;
}

.ratings-table {
  margin: 20px 0 40px;
  border: 1px solid #ddd;
}
@media (max-width: 767px) {
  .ratings-table {
    margin-bottom: 30px;
  }
}
.ratings-table input[type=checkbox], .ratings-table input[type=radio] {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 600px) {
  .ratings-table thead {
    display: none;
  }
}
.ratings-table thead th {
  font-weight: 600;
  font-size: 11px;
  padding: 3px 15px;
  color: #444;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
  background-color: #f6f6f6;
  text-align: center;
}
.ratings-table tbody td {
  font-size: 13px;
  text-align: center;
}
.ratings-table tbody td:first-child {
  font-weight: 700;
  text-align: left;
}

.product-desc-area ul {
  padding-left: 15px;
  margin-left: 2px;
}
.product-desc-area ul li {
  line-height: 20px;
}
.product-desc-area p {
  margin-bottom: 15px;
}

.product-tags-area label {
  display: block;
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 400;
}
.product-tags-area .form-control.pull-left {
  width: 250px;
  margin-right: 10px;
}

.collateral-box ul, .collateral-box ol {
  margin-top: -20px;
}
.collateral-box ul li, .collateral-box ol li {
  border-bottom: 1px solid #eee;
  padding: 20px 0 10px;
}

.add-product-review {
  padding-top: 15px;
}

.panel-group.produt-panel {
  margin-bottom: 55px;
  border: none;
}
@media (min-width: 992px) {
  .panel-group.produt-panel {
    margin-bottom: 65px;
  }
}
.panel-group.produt-panel .panel.panel-default {
  border: none;
}
.panel-group.produt-panel .panel.panel-default .panel-heading {
  background-color: transparent;
}
.panel-group.produt-panel .panel.panel-default .panel-heading .panel-title {
  font-size: 15px;
}
.panel-group.produt-panel .panel.panel-default .panel-heading .panel-title a {
  position: relative;
  border-radius: 0;
  background-color: #f4f4f4;
  border-left: 4px solid #9e1c2f;
}
.panel-group.produt-panel .panel.panel-default .panel-heading .panel-title a:before {
  content: "\f0d7";
  color: #fff;
  font-family: "FontAwesome";
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -10px;
}
.panel-group.produt-panel .panel.panel-default .panel-heading .panel-title a.collapsed:before {
  content: "\f0da";
  color: #9e1c2f;
}
.panel-group.produt-panel .panel.panel-default .panel-heading .panel-title a:not(.collapsed) {
  color: #fff;
  background-color: #9e1c2f;
}

@media (max-width: 480px) {
  .cart h1 span {
    display: block;
    margin-bottom: 10px;
  }
  .cart h1 .pull-right {
    float: none !important;
  }
}

.btn.btn-default.hover-primary:hover, .btn.btn-default.hover-primary:focus {
  color: #fff;
  background-color: #9e1c2f;
  border-color: #9e1c2f;
}

.btn-remove {
  display: inline-block;
  width: 34px;
  height: 34px;
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  padding: 5px 0;
  color: #9e1c2f;
}
.btn-remove:hover, .btn-remove:focus {
  color: #b82137;
  text-decoration: none;
}

.qty-holder {
  display: inline-block;
  width: 125px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 0;
}

.qty-dec-btn,
.qty-inc-btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #f4f4f4;
  border: 1px solid #ccc;
  color: #444;
  line-height: 30px;
  border-radius: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
}
.qty-dec-btn:hover, .qty-dec-btn:focus,
.qty-inc-btn:hover,
.qty-inc-btn:focus {
  color: #9e1c2f;
  background: #f4f4f4;
  text-decoration: none;
}

.qty-input {
  display: inline-block;
  vertical-align: middle;
  width: 35px !important;
  font-size: 14px;
  text-align: center;
  color: #444;
  height: 30px;
  border-radius: 0;
  border: 1px solid #ccc;
  margin: 0 -1px;
  outline: none;
}

.edit-qty {
  display: inline-block;
  font-size: 14px;
  margin-left: 8px;
  color: #9e1c2f;
  vertical-align: middle;
}
.edit-qty:hover, .edit-qty:focus {
  color: #b82137;
  text-decoration: none;
}

.cart-table-wrap {
  border: 1px solid #ececec;
  border-radius: 0;
  background: #fff;
  display: block;
  padding: 30px;
  margin-bottom: 50px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
}
@media (min-width: 992px) {
  .cart-table-wrap {
    margin-bottom: 60px;
  }
}

.cart-table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  font-size: 14px;
}
.cart-table thead tr {
  border-bottom: 1px solid #dcdcdc;
}
.cart-table thead tr th {
  font-weight: 600;
  padding: 15px 10px;
  color: #444;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1;
}
.cart-table tbody tr td {
  border-bottom: 1px solid #dcdcdc;
  padding: 15px 10px;
  line-height: 1.3;
}
.cart-table tbody tr td.product-action-td {
  padding-left: 0;
  padding-right: 0;
}
.cart-table tbody tr td.product-image-td a {
  display: block;
}
.cart-table tbody tr td.product-image-td a img {
  display: block;
  width: 100px;
  height: auto;
}
.cart-table tbody tr td.product-name-td h2 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.cart-table tbody tr td.product-name-td h2 a {
  color: #9e1c2f;
}
.cart-table tbody tr td.product-name-td h2 a:hover, .cart-table tbody tr td.product-name-td h2 a:focus {
  color: #9e1c2f;
}
.cart-table tfoot td {
  padding: 15px 0 0;
}
.cart-table tfoot .btn.btn-default.btn-continue, .cart-table tfoot .btn.btn-default.btn-support {
  float: right;
}
.cart-table tfoot .btn.btn-default.btn-support {
  margin-right: 10px;
}
.cart-table tfoot .btn.btn-default.btn-update {
  float: right;
  margin-left: 10px;
}
.cart-table tfoot .btn.btn-default.btn-clear {
  float: right;
}

@media (max-width: 1199px) {
  .cart-table thead {
    display: none;
  }
  .cart-table tbody tr {
    position: relative;
    display: block;
    border-bottom: 1px solid #dcdcdc;
    padding: 25px 0;
  }
  .cart-table tbody tr td {
    display: block;
    padding: 0 0 15px;
    width: 100%;
    border-width: 0;
    text-align: center !important;
  }
  .cart-table tbody tr td:last-child {
    padding-bottom: 0;
  }
  .cart-table tbody tr td .qty-holder {
    width: 90px;
  }
  .cart-table tbody tr td.product-action-td {
    position: absolute;
    top: 20px;
    z-index: 1;
  }
  .cart-table tbody tr td.product-action-td .btn-remove {
    float: right;
  }
  .cart-table tbody tr td.product-image-td {
    padding-bottom: 15px;
  }
  .cart-table tbody tr td.product-image-td a img {
    margin: 0 auto;
  }
  .cart-table tbody tr:first-child {
    padding-top: 0;
  }
  .cart-table tbody tr:first-child td.product-action-td {
    top: -5px;
  }
  .cart-table tfoot .btn.btn-default.btn-continue, .cart-table tfoot .btn.btn-default.btn-update, .cart-table tfoot .btn.btn-default.btn-clear {
    float: none;
    display: block;
    width: 100%;
    margin: 0 0 10px;
  }
}
.cart .sidebar.shop-sidebar .panel.panel-default .panel-heading a {
  color: #000;
}
.cart .sidebar.shop-sidebar .panel.panel-default .panel-body {
  padding: 15px;
}
.cart .sidebar.shop-sidebar .form-control {
  font-size: 13px;
}
.cart .sidebar.shop-sidebar .panel p {
  font-size: 14px;
}
.cart .sidebar.shop-sidebar .panel .btn-block + .btn-block {
  margin-top: 0;
}
.cart .sidebar.shop-sidebar .panel .btn-link {
  font-size: 13px;
}

.totals-table {
  width: 100%;
  margin-bottom: 5px;
}
.totals-table tbody tr {
  border-bottom: 1px solid #dcdcdc;
}
.totals-table tbody tr:last-child {
  border-bottom: none;
}
.totals-table tbody tr td {
  padding: 10px;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 300;
  text-align: left !important;
}
.totals-table tbody tr td:last-child {
  color: #000;
  text-align: right !important;
  font-weight: 600;
}
.totals-table tbody tr:last-child td:last-child {
  font-size: 17px;
}

.crosssell-products {
  margin-bottom: 25px;
}
.crosssell-products .product.product-sm .product-details-area {
  padding-top: 4px;
  padding-bottom: 0;
}
.crosssell-products .product.product-sm .product-name {
  font-size: 13px;
  margin-bottom: 2px;
}
.crosssell-products .product.product-sm .product-price-box {
  margin-top: 0;
  margin-bottom: 4px;
}
.crosssell-products .product.product-sm .product-price-box .old-price {
  font-size: 14px;
}
.crosssell-products .product.product-sm .product-price-box .product-price {
  font-size: 20px;
}
.crosssell-products .product.product-sm .btn {
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 25px;
}

.checkout-menu {
  margin-bottom: 10px;
}
.checkout-menu .btn {
  font-size: 13px;
  padding: 9px 19px;
}
.checkout-menu .btn i {
  margin-right: 4px;
}

.checkout-review-dropdown .dropdown-menu {
  position: absolute;
  right: 0;
  top: 40px;
  width: 300px;
  background-color: #fff;
  border-width: 6px 0 0 0;
  border-style: solid;
  border-color: #9e1c2f;
  z-index: 1;
  border-radius: 0 0 0 0;
  color: #444;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  padding: 30px 15px 30px 10px;
}
@media (max-width: 320px) {
  .checkout-review-dropdown .dropdown-menu {
    width: 260px;
  }
}
.checkout-review-dropdown .dropdown-menu h3 {
  font-weight: 600;
  color: #404040;
  font-size: 16px;
  border-bottom: 1px solid #b6b6b6;
  line-height: 1;
  padding-bottom: 13px;
  padding-left: 9px;
  margin-bottom: 20px;
}
.checkout-review-dropdown .dropdown-menu table {
  width: 100%;
  border: 0;
  border-spacing: 0;
}
.checkout-review-dropdown .dropdown-menu table td:last-child,
.checkout-review-dropdown .dropdown-menu table th:last-child {
  padding-right: 0;
}
.checkout-review-dropdown .dropdown-menu table td {
  border-bottom: 1px solid #eaeaea;
  color: #676767;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
}
.checkout-review-dropdown .dropdown-menu table thead th {
  font-weight: 400;
  color: #1c1c1c;
  font-size: 15px;
  line-height: 1;
  padding: 0 15px 7px;
  border-bottom: 1px solid #eaeaea;
}
.checkout-review-dropdown .dropdown-menu table tbody td {
  padding: 14px;
  line-height: 1.4;
}
.checkout-review-dropdown .dropdown-menu table tfoot td {
  padding: 10px 0;
  line-height: 1;
}

.form-col {
  color: #393939;
  margin-bottom: 30px;
}
.form-col h3 {
  font-weight: 600;
  color: #404040;
  font-size: 16px;
  text-align: left;
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 8px;
  line-height: 1;
  margin-bottom: 20px;
  text-transform: none;
}
.form-col h3.no-border {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.form-col label {
  display: block;
  color: #393939;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.25;
  margin-bottom: 2px;
}
@media (max-width: 767px) {
  .form-col .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .form-col .row [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
.form-col .form-group {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .form-col .form-group.margin-left {
    margin-left: 4%;
  }
  .form-col .form-group.margin-left .form-control {
    width: 182px;
  }
}
.form-col .form-group.wide .form-control {
  display: block;
  width: 100%;
}
.form-col .form-group.wide .form-control.pull-left {
  width: auto;
}
.form-col .form-group .form-control {
  margin-bottom: 0;
  font-size: 13px;
}
@media (min-width: 992px) {
  .form-col .form-group .form-control {
    display: inline-block;
    width: auto;
    width: 100%;
  }
}
.form-col .form-group .checkbox label {
  font-size: 13px;
}
.form-col .ship-list {
  padding-left: 0;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 14px;
}
.form-col .ship-list li {
  margin-bottom: 5px;
}
.form-col .ship-list li:nth-child(2n) {
  padding-left: 5px;
}
.form-col .expand-plus {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 0;
  background-color: #9e1c2f;
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 14px;
  vertical-align: middle;
  margin-left: 16px;
  text-decoration: none;
}
.form-col .expand-plus:before {
  content: '-';
}
.form-col .expand-plus.collapsed:before {
  content: '+';
}
.form-col .expand-plus:hover, .form-col .expand-plus:focus {
  text-decoration: none;
}
.form-col #discountArea {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #b6b6b6;
}
.form-col #discountArea.collapsing {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.form-col .checkout-payment-method .radio {
  margin-bottom: 14px;
}
.form-col #payment-credit-card-area {
  display: none;
}
.form-col #payment-credit-card-area.show {
  display: block;
}
.form-col .checkout-review-action {
  border-top: 1px solid #b6b6b6;
}
.form-col .checkout-review-action h5 {
  color: #3f3f3f;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}
.form-col .checkout-review-action h5 span {
  margin-left: 45px;
}

.modal-open,
body,
.modal {
  padding-right: 0 !important;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
}
.modal-backdrop.in {
  opacity: 1;
}

.modal {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.modal label {
  margin-bottom: 2px;
}
.modal .modal-header {
  padding: 20px;
  border-bottom: none;
}
.modal .modal-header h4 {
  display: inline-block;
  color: #4a4a4a;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.modal .modal-header h3 {
  margin: 0;
}
.modal .modal-header .close {
  font-size: 20px;
  opacity: 0.95;
  margin-top: -14px;
  margin-right: -10px;
}
.modal .modal-content {
  border: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}
.modal .modal-body {
  padding: 0 20px 20px;
}
.modal .modal-body p {
  font-size: 14px;
  color: #393939;
  margin: 0 0 10px;
}
.modal .modal-footer {
  border-top: 1px solid #e5e5e5;
  padding: 19px 20px 20px;
  margin-top: 15px;
  vertical-align: middle;
}
.modal .modal-footer .btn {
  font-size: 13px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.modal .modal-footer .btn.btn-link {
  font-size: 12px;
  padding: 9.5px 0;
}
.modal .modal-footer:after {
  content: '';
  display: table;
  clear: both;
}

.alert.success-msg {
  font-size: 14px;
}

.panel-box {
  margin-bottom: 30px;
}
.panel-box a {
  color: #9e1c2f;
}
.panel-box a:hover, .panel-box a:focus {
  color: #b82137;
}
.panel-box .panel-box-title {
  padding: 10px 15px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  position: relative;
  border-radius: 0 0 0 0;
}
.panel-box .panel-box-title:after {
  content: '';
  display: table;
  clear: both;
}
.panel-box .panel-box-title h3, .panel-box .panel-box-title h4 {
  float: left;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  color: #313131;
}
.panel-box .panel-box-title .panel-box-edit {
  float: right;
  line-height: 18px;
  font-size: 12px;
}
.panel-box .panel-box-content {
  padding: 20px 15px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 0 0 0 0;
  background-color: #fbfbfb;
  min-height: 135px;
}

#account-chage-pass {
  display: none;
  margin-top: 30px;
}
#account-chage-pass.show {
  display: block;
}

.featured-box {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
}
.featured-box.featured-box-flat {
  background: #fff;
}
.featured-box h4 {
  font-size: 16px;
}

label .required {
  color: #eb340a;
}

.form-section {
  overflow: hidden;
}
.form-section .featured-box {
  margin-bottom: 30px;
}
.form-section .featured-box .box-content {
  padding: 35px 25px;
}
@media (min-width: 768px) {
  .form-section .form-content {
    min-height: 275px;
  }
}
.form-section p {
  margin-bottom: 15px;
}
.form-section .form-action {
  padding-top: 8px;
  margin-top: 1em;
  text-align: right;
}
.form-section .form-action a:not(.btn) {
  color: #9e1c2f;
}
.form-section .form-action a:not(.btn).pull-left {
  line-height: 38px;
}
.form-section .form-action a:not(.btn):hover, .form-section .form-action a:not(.btn):focus {
  color: #a71e32;
  text-decoration: underline;
}
.form-section .required {
  color: #eb340a;
  font-size: 11px;
  text-align: right;
}
@media (min-width: 992px) {
  .form-section.register-form .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .form-section.register-form [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.blog-posts article {
  padding-bottom: 18px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .blog-posts + .toolbar {
    margin-bottom: 35px;
  }
}

article.post-large h2 {
  font-size: 20px;
  margin: 0 0 10px 0;
  font-weight: 600;
}
article.post-large .post-image .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  z-index: 20;
}
article.post-large .post-video iframe {
  border: none;
}
article.post-large .post-content {
  font-size: 14px;
}
article.post-large .post-content p {
  margin-bottom: 15px;
}
article.post-large .post-content p:last-of-type {
  margin-bottom: 12px;
}
article.post-large .btn.btn-link {
  font-size: 13px;
  padding: 0;
  color: #444;
}
article.post-large .btn.btn-link:hover, article.post-large .btn.btn-link:focus {
  color: #9e1c2f;
}
article.post-large .post-meta {
  font-size: 13px;
  line-height: 1.65;
  margin-top: 15px;
}
article.post-large .post-meta > span {
  display: inline;
  padding-right: 15px;
}
article.post-large .post-meta > span i {
  margin-right: 7px;
}
article.post-large .post-meta span,
article.post-large .post-meta a {
  color: #444;
}
article.post-large .post-meta a:hover,
article.post-large .post-meta a:focus {
  color: #9e1c2f;
}

aside ul.nav-list > li > a {
  font-size: 13px;
  padding-left: 21px;
}
aside ul.nav-list > li > a:before {
  margin-left: -11px;
}
aside ul.nav-list > li.active > a {
  color: #9e1c2f;
  font-weight: 600;
}
aside ul.nav-list > li.active > a:before {
  border-left-color: #9e1c2f;
}

.sidebar h4 {
  margin: 5px 0 10px;
  line-height: 1.2;
  font-size: 16px;
  font-weight: bold;
  color: #313131;
}
.sidebar .nav.nav-list {
  margin-bottom: 30px;
  flex-direction: column;
}
.sidebar .simple-post-list {
  margin-bottom: 40px;
}
.sidebar .simple-post-list li {
  padding: 10px 0;
  border-bottom: none;
}
.sidebar .simple-post-list li a {
  font-size: 13px;
}
.sidebar .simple-post-list li .post-image {
  width: 60px;
  line-height: 0;
}
.sidebar .simple-post-list li .post-image .img-thumbnail {
  padding: 2px;
}
.sidebar .simple-post-list li .post-image img {
  display: block;
  max-width: 100%;
  height: auto;
}
.sidebar .simple-post-list li .post-meta {
  font-size: 12px;
}
.sidebar .tagcloud {
  margin-bottom: 30px;
}
.sidebar .tagcloud a {
  display: block;
  padding: 10px 14px;
  background-color: #e1e1e1;
  color: #7e7d79;
  font-size: 13px;
  font-weight: 400;
  line-height: 11px;
  float: left;
  margin: 0 7px 7px 0;
}
.sidebar .tagcloud a:hover, .sidebar .tagcloud a:focus {
  color: #fff;
  background-color: #9e1c2f;
  text-decoration: none;
}
.sidebar .tagcloud:after {
  content: '';
  display: table;
  clear: both;
}
.sidebar .sidebar-compare-products {
  margin-bottom: 30px;
  font-size: 13px;
}

.post-share {
  margin-top: 30px;
}

.post-block h3 {
  font-size: 18px;
  font-weight: 600;
}

.section-contact-area {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .section-contact-area {
    padding-bottom: 40px;
  }
}

label {
  font-size: 14px;
}

.form-control {
  border-radius: 0;
  height: 38px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn {
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.google-map {
  height: 300px;
  margin: 0 0 30px;
}

.list-contact li {
  margin-bottom: 15px;
}
.list-contact li i {
  display: block;
  float: left;
  width: 43px;
  height: 43px;
  background-color: #9e1c2f;
  color: #fff;
  line-height: 43px;
  text-align: center;
  font-size: 16px;
  border-radius: 0;
}
.list-contact li i.fa-mobile {
  font-size: 24px;
}
.list-contact li div {
  margin-left: 55px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 21px;
}

#footer {
  background-color: #f7f7f7;
  color: #444;
  font-size: 13px;
  padding-top: 41px;
  border-top: none;
}
#footer .footer-ribbon {
  margin-top: -61px;
}
#footer .footer-ribbon span {
  font-size: 20px;
  line-height: 1;
}
#footer h1, #footer h2, #footer h3, #footer h4 {
  color: #9e1c2f;
}
#footer h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 18px;
}
#footer a {
  color: #444;
}
#footer a:hover, #footer a:focus {
  color: #444;
  text-decoration: underline;
}
#footer .contact {
  margin-top: -6px;
}
#footer .contact li {
  padding: 5px 0;
  line-height: 1.5;
  margin-bottom: 0;
}
#footer .contact li:last-child {
  margin-bottom: 0;
}
#footer .contact p {
  margin-bottom: 0;
  line-height: 1.5;
}
#footer .contact i {
  color: #444;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  top: 2px;
}
#footer .links,
#footer .features {
  list-style: none;
  padding: 0;
  margin-top: -6px;
}
#footer .links li,
#footer .features li {
  position: relative;
  padding: 10.5px 0;
  line-height: 1;
  display: block;
}
#footer .links li i,
#footer .features li i {
  margin-right: 3px;
}
#footer .newsletter form {
  opacity: 1;
}
#footer .newsletter .btn {
  padding-top: 6px;
  padding-bottom: 6px;
}
#footer .newsletter .form-control {
  border-color: #dbdbdb;
  background-color: #dbdbdb;
}
#footer .newsletter p {
  margin-bottom: 15px;
  line-height: 1.5;
}
@media (min-width: 992px) {
  #footer .newsletter p.newsletter-info {
    margin-bottom: 40px;
  }
}
#footer .footer-copyright {
  color: #444;
  background-color: #fff;
  border-top: none;
  padding: 20px 0;
  margin-top: 20px;
}
#footer .footer-copyright p {
  color: #444;
}
#footer .footer-copyright .footer-payment {
  display: block;
  max-width: 100%;
  height: auto;
}
#footer .footer-copyright .social-icons li {
  margin-top: 0;
}
#footer .footer-copyright .social-icons li + li {
  margin-left: 7px;
}
#footer .footer-copyright .social-icons li a {
  color: #fff !important;
}
#footer .footer-copyright .social-icons li a:not(:hover) {
  background-color: #9e9e9e;
}
@media (min-width: 992px) {
  #footer .footer-copyright .logo,
  #footer .footer-copyright .social-icons,
  #footer .footer-copyright .footer-payment {
    float: left;
    margin-bottom: 0;
  }
  #footer .footer-copyright .logo {
    margin-right: 45px;
  }
  #footer .footer-copyright .social-icons {
    margin-top: 3px;
    margin-right: 60px;
  }
  #footer .footer-copyright .social-icons li {
    margin-bottom: 0;
  }
  #footer .footer-copyright .footer-payment {
    margin-top: 4px;
  }
  #footer .footer-copyright .copyright-text {
    float: right;
    margin-bottom: 0;
    margin-top: 8.5px;
  }
}
@media (max-width: 991px) {
  #footer .footer-copyright {
    text-align: center;
  }
  #footer .footer-copyright .logo {
    margin-bottom: 8px;
  }
  #footer .footer-copyright .social-icons {
    margin-bottom: 5px;
  }
  #footer .footer-copyright .logo img,
  #footer .footer-copyright .footer-payment {
    margin-left: auto;
    margin-right: auto;
  }
  #footer .footer-copyright .footer-payment {
    margin-bottom: 10px;
  }
}

html .scroll-to-top {
  right: 15px;
  min-width: 40px;
  padding: 9px 5px 31px;
  font-size: 16px;
  color: #9e1c2f;
  border-radius: 0 0 0 0;
}
html .scroll-to-top:hover, html .scroll-to-top:focus {
  color: #9e1c2f;
  background-color: #555;
}

#header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
  font-family: FontAwesome;
  border: none !important;
  margin: 0;
  width: initial !important;
  float: none;
}

.custom-text-color-1 {
  color: #666 !important;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1440px;
  }
}

.main-pages .fullscreen-container, .main-pages .fullwidthbanner-container {
  top: -50px;
}

.flatpickr-calendar .flatpickr-day.selected {
  background-color: #9e1c2f;
  border-color: #9e1c2f;
}
